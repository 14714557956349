import { useEffect, useState } from "react"
import {
  Center,
  Heading,
  Spinner,
  useToast,
} from "@chakra-ui/react"
import { NavLayout } from "../components/NavLayout"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate, useParams } from "react-router-dom"
import { ProductForm } from "../components/Forms/ProductForm"
import { addServerErrors } from "../services/add-server-errors"

type FormData = {
  name: string
  options: {
    seat_sizes: string
    tree_sizes: string
    color_and_type_of_leather: string
    flap_position: string
    flap_length: string
    calf_roll_type: string
    calf_roll_size: string
    knee_roll_type: string
    knee_roll_size: string
    panel_type: string
    gusset_depth: string
    billets: string
    girth_color: string
    girth_size: string
    leathers_color: string
    leathers_size: string
    head_type: string
    seat_welting: string
    cantle_welting: string
    front_facing: string
    rear_facing: string
    keeper: string
    keeper_style: string
    cantlepiece: string
    cantlepiece_full_patent: string
    stitching: string
  }
  active: string
}

type Errors = {
  [key: string]: { message: string }
}

const initialFormData: FormData = {
  name: "",
  options: {
    seat_sizes: "",
    tree_sizes: "",
    color_and_type_of_leather: "",
    flap_position: "",
    flap_length: "",
    calf_roll_type: "",
    calf_roll_size: "",
    knee_roll_type: "",
    knee_roll_size: "",
    panel_type: "",
    gusset_depth: "",
    billets: "",
    girth_color: "",
    girth_size: "",
    leathers_color: "",
    leathers_size: "",
    head_type: "",
    seat_welting: "",
    cantle_welting: "",
    front_facing: "",
    rear_facing: "",
    keeper: "",
    keeper_style: "",
    cantlepiece: "",
    cantlepiece_full_patent: "",
    stitching: "",
  },
  active: "1",
}

const initialErrors: Errors = {}

export const EditProduct = () => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const toast = useToast()
  const { productId } = useParams()
  const [data, setData] = useState(initialFormData)
  const [errors, setErrors] = useState(initialErrors)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const endpoint = `/products/edit/${productId}`

  useEffect(() => {
    setLoading(true)
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
        try {
          const response = await axiosPrivate.get(endpoint, {
            signal: controller.signal
          })
          const options = structuredClone(response.data.product.options)
          for (const k in options) {
            options[k] = options[k].join(', ')
          }
          isMounted && setData({ ...response.data.product, ...{ options: {...options }}})
          setLoading(false)
        } catch (err: any) {
          console.error(err)
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            isClosable: true,
          })
          navigate(-1)
        }
    }

    const timeout = setTimeout(getData, 500)

    return () => {
        isMounted = false
        isMounted && controller.abort()
        clearTimeout(timeout)
    }
  }, [axiosPrivate, endpoint, navigate, toast])

  const handleSubmit = async (formData: FormData) => {
    setSubmitting(true)
    try {
      const response = await axiosPrivate.post(endpoint, formData)

      if (!response?.data?.product?.errors) {
        toast({
          title: 'Success',
          description: 'Product successfully updated!',
          status: 'success',
          isClosable: true,
        })
        navigate(-1)
      } else {
        addServerErrors(response?.data?.product?.errors, setErrors)
        toast({
          title: 'Error',
          description: 'Please review any errors and resubmit',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
    setSubmitting(false)
  }

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Edit Product</Heading>
      }
    >
      {!loading
        ? (
          <ProductForm errors={errors} handleSubmit={handleSubmit} initialFormData={data} loading={submitting} />
        ) : (
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        )
      }
    </NavLayout>
  )
}
