import { useState } from "react"
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Flex,
  Divider,
  useToast,
} from "@chakra-ui/react"
import { NavLayout } from "../components/NavLayout";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { addServerErrors } from "../services/add-server-errors";
import { CustomerForm } from "../components/Forms/CustomerForm";

type FormData = {
  firstName: string;
  lastName: string;
  street_address_1: string;
  street_address_2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  phone: string;
  email: string;
};

type Errors = {
    [key: string]: { message: string }
}

const initialFormData: FormData = {
  firstName: "",
  lastName: "",
  street_address_1: "",
  street_address_2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
  phone: "",
  email: "",
};

const initialErrors: Errors = {}

export const AddCustomer = () => {
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const toast = useToast()
    const [errors, setErrors] = useState(initialErrors)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (formData: FormData) => {
      setLoading(true)
      try {
        const response = await axiosPrivate.post('/customers/add', formData)

        if (!response?.data?.customer?.errors) {
          toast({
            title: 'Success',
            description: 'Customer added successfully!',
            status: 'success',
            isClosable: true,
          })
          navigate(-1)
        } else {
          addServerErrors(response?.data?.customer?.errors, setErrors)
          toast({
            title: 'Error',
            description: 'Please review any errors and resubmit',
            status: 'error',
            isClosable: true,
          })
        }
      } catch (e: any) {
        toast({
          title: 'Error',
          description: e.message,
          status: 'error',
          isClosable: true,
        })
      }
      setLoading(false)
    }

    return (
      <NavLayout
        left={
          <Heading as="h1" size="lg">Add Customer</Heading>
        }
      >
        <CustomerForm edit={false} errors={errors} handleSubmit={handleSubmit} initialFormData={initialFormData} loading={loading} />
      </NavLayout>
    )
}
