import { Dispatch, SetStateAction } from "react"

export function addServerErrors<T>(
    errors: { [P in keyof T]?: string[] },
    setError: Dispatch<SetStateAction<any>>
) {
    let parsed: any = {}
    Object.keys(errors).forEach((key) => {
        parsed[key as keyof T] = {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            message: Object.values(errors[key as keyof T]!).join(". "),
        }
    })

    setError(parsed)
}
