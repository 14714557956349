/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useState } from "react"
import {
  Button,
  Heading,
  useToast,
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { ColumnDef } from "@tanstack/react-table"
import { AddIcon } from "@chakra-ui/icons"
import { PaginatedTable } from "../components/PaginatedTable"
import { Order } from "../models/Order"
import { NavLayout } from "../components/NavLayout"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"

export const Orders = () => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const toast = useToast()

  const columns = useMemo<ColumnDef<Order>[]>(
    () => [
      {
        accessorKey: 'id',
        id: 'id',
      },
      {
        accessorKey: 'order_number',
        header: () => 'Order #',
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.user ? `${row.user.first_name} ${row.user.last_name}` : 'Unknown',
        accessorKey: 'user',
        header: () => 'User/Rep',
        footer: props => props.column.id,
        canFilter: false,
      },
      {
        accessorFn: row => row.customer ? `${row.customer.first_name} ${row.customer.last_name}` : 'Unknown',
        accessorKey: 'customer',
        header: () => 'Customer',
        footer: props => props.column.id,
        canFilter: false,
      },
      {
        accessorFn: row => row?.order_details?.product_name ?? 'Unknown',
        accessorKey: 'product_name',
        header: () => 'Product',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'job_ticket',
        header: () => 'Job Ticket',
        footer: props => props.column.id,
        meta: {
          canEdit: true
        }
      },
      {
        accessorFn: row => row?.saddler ? `${row?.saddler?.name} (${row?.saddler?.number})` : '',
        accessorKey: 'saddler',
        header: () => 'Saddler',
        footer: props => props.column.id,
        meta: {
          canEdit: true,
          getOptions: async () => {
            const controller = new AbortController()
            try {
              const response = await axiosPrivate.get('/saddlers/get-list', {
                signal: controller.signal
              })
              return response.data.saddlers
            } catch (err) {
              console.error(err)
            }
          }
        }
      },
      {
        accessorKey: 'serial_number',
        header: () => 'Serial #',
        footer: props => props.column.id,
        meta: {
          canEdit: true
        }
      },
      {
        accessorFn: (row) => {
          if (typeof row.paperwork_received === 'boolean') {
            return row.paperwork_received ? 'Yes' : 'No'
          }
          return row.paperwork_received === 'true' ? 'Yes' : 'No'
        },
        accessorKey: 'paperwork_received',
        header: () => 'Paperwork?',
        footer: props => props.column.id,
        meta: {
          canEdit: true,
          getOptions: async () => {
            return [
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]
          }
        }
      },
      {
        accessorFn: row => row.estimated_ship_date ?? '',
        accessorKey: 'estimated_ship_date',
        header: () => 'Estimated Ship Date',
        footer: props => props.column.id,
        meta: {
          canEdit: true
        }
      },
      {
        accessorFn: row => row.actual_ship_date ?? '',
        accessorKey: 'actual_ship_date',
        header: () => 'Actual Ship Date',
        footer: props => props.column.id,
        meta: {
          canEdit: true
        }
      },
      {
        accessorFn: row => row.created ? row.created.toString().split('T')[0] : '',
        accessorKey: 'created',
        header: () => 'Order Date',
        footer: props => props.column.id,
      },
    ],
    [auth?.user?.timezone]
  )

  const defaultColumn: Partial<ColumnDef<Order>> = {
    cell: ({ getValue, row: { index, original }, column: { id, columnDef }, table }) => {
      const initialValue = (original[id] !== null && typeof original[id] === 'object' ? original[id]?.id : original[id]) ?? ''

      const [value, setValue] = useState(initialValue)
      const [isEditing, setIsEditing] = useState(false)
      const [options, setOptions] = useState<Array<any>>([])

      const isSelect = typeof columnDef.meta?.getOptions === 'function'

      const onIsEditing = async () => {
        if (typeof columnDef.meta?.getOptions === 'function') {
          const options = await columnDef.meta?.getOptions()
          setOptions(options)
        }
        setIsEditing(true)
      }

      const onBlur = async () => {
        let option: any = undefined
        if (isSelect) {
          option = options.find(option => option.id == value)
        }
        if (value == initialValue) {
          setIsEditing(false)
          return
        }
        table.options.meta?.updateData(index, id, option ?? value)
        try {
          let valueToPersist = option?.id ?? value
          if (valueToPersist === 'true') valueToPersist = 1
          if (valueToPersist === 'false') valueToPersist = 0
          const response = await axiosPrivate.post(`/orders/edit/${original.id}`, {
            [option ? `${id}_id` : id]: valueToPersist,
          })
          if (!response?.data?.order?.errors) {
            toast({
              title: 'Success',
              description: 'Order saved successfully!',
              status: 'success',
              isClosable: true,
            })
            if (response?.data?.order?.job_ticket) {
              table.options.meta?.updateData(index, 'job_ticket', response?.data?.order?.job_ticket)
            }
          } else {
            console.log(response?.data?.order?.errors)
            toast({
              title: 'Error',
              description: 'Please review any errors and resubmit',
              status: 'error',
              isClosable: true,
            })
          }
        } catch (e: any) {
          toast({
            title: 'Error',
            description: e.message,
            status: 'error',
            isClosable: true,
          })
        }
        setIsEditing(false)
      }

      useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      let field = <div />
      if (isSelect) {
        field = (
          <select
            value={value as string}
            onChange={e => setValue(e.target.value)}
            onBlur={onBlur}
            autoFocus
          >
            {options.map(option => (
              <option key={option.id} value={option.id}>
                {option.name}{option?.number ? ` (${option?.number})` : ''}</option>
            ))}
          </select>
        )
      } else {
        field = (
          <input
            value={value as string}
            onChange={e => setValue(e.target.value)}
            onBlur={onBlur}
            autoFocus
          />
        )
      }

      if (columnDef.meta?.canEdit === true) {
        return (
          isEditing ? (
            field
          ) : (
            <div onClick={onIsEditing} style={{ display: 'flex', height: '100%', alignItems: 'center', cursor: 'pointer' }}>
              {getValue() as string} &nbsp;
            </div>
          )
        )
      } else {
        return getValue() as string
      }
    },
  }

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Orders</Heading>
      }
      right={
        <Button
          as={ReactRouterLink}
          to="/orders/add"
          boxShadow="dark-lg"
          variant="solid"
          colorScheme="green"
          aria-label="Add Order"
          fontSize="20px"
          leftIcon={<AddIcon />}
        >
          Add Order
        </Button>
      }
    >
      <PaginatedTable<Order> dataKey="orders" singular="Order" endpoint="/orders/index" columns={columns} defaultColumn={defaultColumn}/>
    </NavLayout>
  )
}
