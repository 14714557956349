import { useEffect, useState } from "react"
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  ColumnFiltersState,
  RowData,
} from '@tanstack/react-table'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { ChevronDownIcon, DownloadIcon, EditIcon, SearchIcon, SettingsIcon } from "@chakra-ui/icons"
import { PaginatedTableFilter } from "./PaginatedTableFilter"
import { DeleteModal } from "./DeleteModal"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import useAuth from "../hooks/useAuth"
import { AxiosInstance } from "axios"
import { FaFileExcel, FaFilePdf } from "react-icons/fa"

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }
  interface ColumnMeta<TData extends RowData, TValue> {
    canEdit?: boolean
    getOptions?: () => Promise<any>
  }
}

type props<Type> = {
  dataKey: string
  endpoint: string
  singular: string
  altRouteAccessor?: string
  columns: ColumnDef<Type>[]
  defaultColumn?: Partial<ColumnDef<Type, unknown>> | undefined
}

type ExtraFilters = {
  archived?: boolean
  in_work?: string
};

type PagingFromLastResponse = {
  start: number
  end: number
  total: number
};

const downloadFile = async (axios: AxiosInstance, url: string, filename: string, type: string, loadingFn: (val: boolean) => void): Promise<void> => {
  loadingFn(true);
  const response = await axios.get(url, { responseType: 'blob' });
  loadingFn(false);

  const file = new window.File([response.data], filename, { type });

  const a = document.createElement('a');
  const objUrl = window.URL.createObjectURL(file);
  a.href = objUrl;
  a.download = file.name;
  a.click();
  window.URL.revokeObjectURL(objUrl);
};

const getFilters = (columnFilters: ColumnFiltersState) => columnFilters.reduce(
  (obj: Object, item: any) => Object.assign(obj, { [item.id]: item.value }),
  {}
);

const getDetailsFilter = (obj) => Object.entries(obj).reduce((obj, [key, value]) => {
  obj[`order_details[${key}]`] = value;
  return obj;
}, {});

export const PaginatedTable = <Type,>({ dataKey, endpoint, singular, altRouteAccessor, columns, defaultColumn }: props<Type>) => {
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  const marginsForControls = useBreakpointValue({
    base: 0,
    sm: 15,
    md: 0,
    lg: 0,
  })
  const [data, setData] = useState<Array<Type>>([])
  const [pageCount, setPageCount] = useState(1)
  const [pagingFromLastResponse, setPagingFromLastResponse] = useState<PagingFromLastResponse>({
    start: 0,
    end: 0,
    total: 0,
  })
  const [loading, setLoading] = useState(true)
  const [render, setRender] = useState(false)
  const [extraFilters, setExtraFilters] = useState<ExtraFilters>(((altRouteAccessor ?? dataKey) === 'orders') ? { archived: false } : {});

  const searchParams = new URLSearchParams(window.location.search)

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: pageCount,
    state: {
      columnVisibility: { id: false },
    },
    initialState: {
      pagination: {
        pageIndex: parseInt(searchParams.get('page') ?? '1') - 1,
        pageSize: parseInt(searchParams.get('limit') ?? '10'),
      },
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
    autoResetPageIndex: false,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
  })

  const pageIndex = table.getState().pagination.pageIndex
  const pageSize = table.getState().pagination.pageSize
  const columnFilters = table.getState().columnFilters

  const isOrders = (altRouteAccessor ?? dataKey) === 'orders'; // Don't like that we are injecting based on dataKey here. Probably just pass JSX actions through props

  const [detailsSearch, setDetailsSearch] = useState({});

  const [productOptions, setProductOptions] = useState([]);
  const [selectedProductOption, setSelectedProductOption] = useState('');
  const [selectedProductOptionValue, setSelectedProductOptionValue] = useState('');

  useEffect(() => {
    const getProductOptions = async () => {
      const controller = new AbortController()
      try {
        const response = await axiosPrivate.get('/products/available-options', {
          signal: controller.signal
        })
        setProductOptions(response.data.options)
      } catch (err) {
        console.error(err)
      }
    }
    if (isOrders) {
      getProductOptions();
    }
  }, [axiosPrivate, isOrders]);

  const {
    isOpen: isDetailsSearchOpen,
    onOpen: onDetailsSearchOpen,
    onClose: onDetailsSearchClose
  } = useDisclosure();

  const onDetailsSearchSubmit = async () => {
    if (selectedProductOption && selectedProductOptionValue) {
      setDetailsSearch({ ...detailsSearch, [selectedProductOption]: selectedProductOptionValue });
    }
    onDetailsSearchClose();
    setSelectedProductOption('');
    setSelectedProductOptionValue('');
  };

  const onDetailsSearchRemove = (key: string) => {
    const newDetailsSearch = { ...detailsSearch };
    delete newDetailsSearch[key];
    setDetailsSearch(newDetailsSearch);
  }

  useEffect(() => {
    setLoading(true)
    setRender(false)
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
        try {
          const filters = getFilters(columnFilters);
          const detailsFilter = getDetailsFilter(detailsSearch);
          const response = await axiosPrivate.get(endpoint, {
            params: {
              page: pageIndex + 1,
              limit: pageSize,
              timezone: auth?.user?.timezone,
              ...filters,
              ...extraFilters,
              ...detailsFilter,
            },
            signal: controller.signal
          })
          setPageCount(response.data?.paging?.pageCount)
          setPagingFromLastResponse({
            start: response.data?.paging?.start,
            end: response.data?.paging?.end,
            total: response.data?.paging?.count,
          })
          isMounted && setData(response.data[dataKey])
          setLoading(false)
        } catch (err) {
          console.error(err)
        }
    }

    const filterTimeout = setTimeout(getData, 500)

    searchParams.set('page', (pageIndex + 1).toString())
    searchParams.set('limit', pageSize.toString())
    window.history.pushState(null, '', `?${searchParams.toString()}`);

    return () => {
        isMounted = false
        isMounted && controller.abort()
        clearTimeout(filterTimeout)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosPrivate, dataKey, endpoint, pageIndex, pageSize, columnFilters, render, auth, extraFilters, detailsSearch])

  const productOptionValues = productOptions[selectedProductOption] ?? [];

  return (
    <>
      {isOrders ?
      (<Box className="flex items-center gap-2">
        <HStack marginLeft={marginsForControls} marginRight={marginsForControls} marginBottom={15}>
          <HStack>
            <FormControl marginEnd="4">
              <Select
                id="in_work"
                value={extraFilters?.in_work ?? ''}
                onChange={e => setExtraFilters({...extraFilters, in_work: e.target.value })}
                w="150px"
              >
                <option value=''>All</option>
                <option value='1'>In Work</option>
                <option value='0'>Not In Work</option>
              </Select>
            </FormControl>
            <FormControl display='flex' alignItems='center'>
              <Switch colorScheme="red" id='archived' isChecked={extraFilters?.archived ?? false} onChange={e => setExtraFilters({...extraFilters, archived: e.target.checked })} />
              <FormLabel htmlFor='archived' mb='0' ml="0.5rem">
                Archived
              </FormLabel>
            </FormControl>
          </HStack>
          <Spacer />
          {Object.entries(detailsSearch).length > 0 && (
            <HStack spacing={3} style={{ borderRight: '1px solid #ddd', paddingRight: '15px', marginRight: '10px' }}>
              {Object.entries(detailsSearch).map(([key, value]) => (
                <Tag key={key} size="lg" variant="subtle" colorScheme="blue">
                  <TagLabel textTransform="capitalize">
                    {key.replaceAll('_', ' ')}: {(value as string).replaceAll('_', ' ')}
                  </TagLabel>
                  <TagCloseButton onClick={() => onDetailsSearchRemove(key)}/>
                </Tag>
              ))}
            </HStack>
          )}
          <Box>
            <Button as={Button} onClick={onDetailsSearchOpen} colorScheme="blue" leftIcon={<SearchIcon />}>
              Search Details
            </Button>
            <Modal isOpen={isDetailsSearchOpen} onClose={onDetailsSearchClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Advanced Search</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Select
                    placeholder="Select a detail"
                    onChange={e => setSelectedProductOption(e.target.value)}
                    textTransform="capitalize"
                  >
                    {Object.keys(productOptions).map((option: any) => (
                      <option key={option} value={option}>
                        {option.replaceAll('_', ' ')}
                      </option>
                    ))}
                  </Select>
                  {selectedProductOption && (
                    <Select
                      placeholder="Select an option"
                      onChange={e => setSelectedProductOptionValue(e.target.value)}
                      textTransform="capitalize"
                      mt="2"
                    >
                      {productOptionValues.map((option: any) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button variant='ghost' mr={3} onClick={onDetailsSearchClose}>
                    Close
                  </Button>
                  <Button colorScheme='blue' onClick={onDetailsSearchSubmit}>
                    Submit
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
          <Box>
            <Menu>
              <MenuButton as={Button} colorScheme="blue" leftIcon={<DownloadIcon />} rightIcon={<ChevronDownIcon />}>
                Download
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<FaFilePdf />}
                  onClick={() => downloadFile(
                    axiosPrivate,
                    `/${altRouteAccessor ?? dataKey}/index.pdf?timezone=${auth?.user?.timezone}${(Object.entries(getFilters(columnFilters)).map(([key, value]) => `&${key}=${value}`)).join('')}`,
                    'all-orders.pdf',
                    'application/pdf',
                    setLoading
                  )}
                >
                  All Orders
                </MenuItem>
                <MenuItem
                  icon={<FaFilePdf />}
                  onClick={() => downloadFile(
                    axiosPrivate,
                    `/${altRouteAccessor ?? dataKey}/index.pdf?type=manifest&timezone=${auth?.user?.timezone}${(Object.entries(getFilters(columnFilters)).map(([key, value]) => `&${key}=${value}`)).join('')}`,
                    'manifest-report.pdf',
                    'application/pdf',
                    setLoading
                  )}
                >
                  Manifest Report
                </MenuItem>
                <MenuItem
                  icon={<FaFileExcel />}
                  onClick={() => downloadFile(
                    axiosPrivate,
                    `/${altRouteAccessor ?? dataKey}/index.xlsx?timezone=${auth?.user?.timezone}${(Object.entries(getFilters(columnFilters)).map(([key, value]) => `&${key}=${value}`)).join('')}`,
                    'order-report.xlsx',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    setLoading
                  )}
                >
                  Orders Report
                </MenuItem>
                <MenuItem
                  icon={<FaFilePdf />}
                  onClick={() => downloadFile(
                    axiosPrivate,
                    `/${altRouteAccessor ?? dataKey}/index.pdf?type=tickets&timezone=${auth?.user?.timezone}${(Object.entries(getFilters(columnFilters)).map(([key, value]) => `&${key}=${value}`)).join('')}`,
                    'job-tickets.pdf',
                    'application/pdf',
                    setLoading
                  )}
                >
                  Job Tickets
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </Box>) : null}
      <TableContainer>
        <Table size="sm" variant="simple" mb="20px">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th key={header.id} colSpan={header.colSpan} minW="150px">
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanFilter() ? (
                            <Box mt={2} mb={2}>
                              <PaginatedTableFilter column={header.column} table={table} />
                            </Box>
                          ) : null}
                        </Box>
                      )}
                    </Th>
                  )
                })}
                <Th key="actions">
                  Actions
                </Th>
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.length && !loading ? (table.getRowModel().rows.map(row => {
              return (
                <Tr
                  key={row.id}
                  sx={{
                    '&:hover': {
                      'boxShadow': [
                        '0 1px 2px 0 rgb(60 64 67 / 10%)',
                        '0 1px 3px 1px rgb(60 64 67 / 5%)',
                      ],
                    }
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <Td key={cell.id} style={{ height: '20px' }}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    )
                  })}
                  <Td w={1}>
                    {((altRouteAccessor ?? dataKey) === 'orders') ? (
                      <IconButton
                        variant="solid"
                        colorScheme="blue"
                        aria-label="Download PDF"
                        fontSize="20px"
                        icon={<DownloadIcon />}
                        marginRight="5px"
                        onClick={() => downloadFile(
                          axiosPrivate,
                          `/${altRouteAccessor ?? dataKey}/view/${row.getValue('id')}.pdf`,
                          `order-${row.getValue('id')}.pdf`,
                          'application/pdf',
                          setLoading
                        )}
                      />
                    ) : null}
                    {((altRouteAccessor ?? dataKey) === 'products') ? (
                      <Link as={ReactRouterLink} to={`/${altRouteAccessor ?? dataKey}/codes/${row.getValue('id')}`}>
                        <IconButton
                          variant="solid"
                          colorScheme="blue"
                          aria-label="Manage Codes"
                          fontSize="20px"
                          icon={<SettingsIcon />}
                          marginRight="5px"
                        />
                      </Link>
                    ) : null}
                    <Link as={ReactRouterLink} to={`/${altRouteAccessor ?? dataKey}/edit/${row.getValue('id')}`}>
                      <IconButton
                        variant="solid"
                        colorScheme="yellow"
                        aria-label="Edit"
                        fontSize="20px"
                        icon={<EditIcon />}
                        marginRight="5px"
                      />
                    </Link>
                    <DeleteModal dataKey={dataKey} itemId={row.getValue('id')} itemType={singular} refresh={() => setRender(true)} />
                  </Td>
                </Tr>
              )
            })) : (
              <Tr>
                <Td colSpan={columns.length}>
                  <Center>
                    {table.getRowModel().rows.length === 0 && !loading ? (
                      <Heading as="p" size="md" color="#AAA" py="16">
                        No Data Matching Search Criteria
                      </Heading>
                    ) : (
                      <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                        my="16"
                      />
                    )}
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Box className="flex items-center gap-2">
        <Flex>
          <Box pt={3}>
            <Text className="flex items-center gap-1" mr={10}>
              Page&nbsp;
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </Text>
          </Box>
          <Box flexGrow="1" alignContent="center">
            <Text textAlign="center">
              {[
                'Showing',
                pagingFromLastResponse.start,
                'to',
                pagingFromLastResponse.end,
                'of',
                pagingFromLastResponse.total,
                'results',
              ].join(' ')}
            </Text>
          </Box>
          <HStack spacing={1}>
            <Button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              isDisabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </Button>
            <Button
              className="border rounded p-1"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              isDisabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </Button>
            <Button
              className="border rounded p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              isDisabled={!table.getCanNextPage()}
            >
              {'>'}
            </Button>
            <Button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
              isDisabled={!table.getCanNextPage()}
            >
              {'>>'}
            </Button>
          </HStack>
        </Flex>
      </Box>
    </>
  )
}
