import axios from "../services/axios"
import useAuth from "./useAuth"

const useRefreshToken = () => {
    const { setAuth } = useAuth()

    const refresh = async () => {
        const response = await axios.get('/users/refresh', {
            withCredentials: true,
        })
        setAuth((prev: any) => {
            return { ...prev, ...response.data }
        })

        return response.data.token
    }

    return refresh
}

export default useRefreshToken
