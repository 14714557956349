import { useEffect, useState } from "react"
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select as ChakraSelect,
  Spinner,
  Stack,
  Switch,
} from "@chakra-ui/react"
import Select from "react-select";
import { useNavigate } from "react-router-dom"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

type FormData = {
  [key: string]: any
}

type Errors = {
  [key: string]: any
}

type props = {
  edit: boolean
  errors: Errors
  handleSubmit: Function
  loading: boolean
  initialFormData: FormData
}

const initialTimezones: { value: string, label: string }[] = []

export const UserForm = ({ edit = false, errors, handleSubmit, loading, initialFormData }: props) => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const [formData, setFormData] = useState(initialFormData)
  const [timezones, setTimezones] = useState(initialTimezones)
  const [defaultTimezones, setDefaultTimezones] = useState(initialTimezones)
  const endpoint = '/users/get-timezones'

  useEffect(() => { setFormData(initialFormData) }, [initialFormData])
  useEffect(() => {
    let isMounted = true
    const getTimezones = async (inputValue: string) => {
      const response = await axiosPrivate.get(endpoint, {
          params: {
              query: inputValue
          }
      })
      const timezones = response.data.timezones.map((t: string) => ({ value: t, label: t }))

      isMounted && setTimezones(timezones)
    }

    const timeout = setTimeout(getTimezones, 500)

    return () => {
      isMounted = false
      clearTimeout(timeout)
    }
  }, [endpoint])


  if (formData.timezone && !defaultTimezones.length) {
    setDefaultTimezones([{ value: formData.timezone, label: formData.timezone }])
  }

  const handleActiveToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, ...{ [e.target.name]: e.target.checked } })
  }

  const handleTimezoneChange = (tz: { value: string, label: string } | null) => {
    setFormData({ ...formData, timezone: tz?.value})
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(formData) }}>
      <Stack spacing="5" px={{ base: '5', md: '10' }} py={{ base: '5', md: '10' }}>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="username" isRequired isInvalid={'username' in errors}>
          <FormLabel>Username</FormLabel>
          <Input type="text" name="username" value={formData.username ?? ""} onChange={handleChange} />
          {errors.username && <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="password" isRequired={!edit} isInvalid={'password' in errors}>
          <FormLabel>Password</FormLabel>
          <Input type="password" name="password" onChange={handleChange} />
          {errors.password && <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="confirm_password" isRequired={!edit} isInvalid={'confirm_password' in errors}>
          <FormLabel>Confirm Password</FormLabel>
          <Input type="password" name="confirm_password" onChange={handleChange} />
          {errors.confirm_password && <FormErrorMessage>{errors?.confirm_password?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="first_name" isRequired isInvalid={'first_name' in errors}>
        <FormLabel>First Name</FormLabel>
          <Input type="text" name="first_name" value={formData.first_name ?? ""} onChange={handleChange} />
          {errors.first_name && <FormErrorMessage>{errors?.first_name?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="last_name" isRequired isInvalid={'last_name' in errors}>
          <FormLabel>Last Name</FormLabel>
          <Input type="text" name="last_name" value={formData.last_name ?? ""} onChange={handleChange} />
          {errors.last_name && <FormErrorMessage>{errors?.last_name?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="phone" isRequired isInvalid={'phone' in errors}>
          <FormLabel>Phone</FormLabel>
          <Input type="tel" name="phone" value={formData.phone ?? ""} onChange={handleChange} />
          {errors.phone && <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="email_address" isRequired isInvalid={'email_address' in errors}>
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email_address" value={formData.email_address ?? ""} onChange={handleChange} />
          {errors.email_address && <FormErrorMessage>{errors?.email_address?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="organization" isRequired isInvalid={'organization' in errors}>
          <FormLabel>Organization</FormLabel>
          <Input type="text" name="organization" value={formData.organization ?? ""} onChange={handleChange} />
          {errors.organization && <FormErrorMessage>{errors?.organization?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="timezone" isRequired isInvalid={'timezone' in errors}>
          <FormLabel>Timezone</FormLabel>
          <Select
            name="timezone"
            value={{ value: formData.timezone, label: formData.timezone }}
            onChange={handleTimezoneChange}
            options={timezones}
            getOptionLabel={(c) => c ? `${c.value}` : 'Search for a Customer'}
            getOptionValue={(c) => `${c.label}`}
          />
          {errors.timezone && <FormErrorMessage>{errors?.timezone?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="role" isRequired isInvalid={'role' in errors}>
          <FormLabel>Role</FormLabel>
          <ChakraSelect name="role" defaultValue={formData.role} onChange={handleChange}>
            <option value="0">Super Admin</option>
            <option value="1">Admin</option>
            <option value="2">User</option>
          </ChakraSelect>
          {errors.role && <FormErrorMessage>{errors?.role?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="active">
          <FormLabel>Active</FormLabel>
          <Switch colorScheme="blue" defaultChecked={!!formData.active} name="active" onChange={handleActiveToggle} />
        </FormControl>
        </Stack>
        <Divider />
        <Flex justifyContent="space-between">
          <Button colorScheme="red" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit" isDisabled={loading}>
          {!loading
            ? 'Submit'
            : (
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='white'
              size='lg'
            />
            )
          }
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}
