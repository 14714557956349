import { useEffect, useState } from "react"
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Switch,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

type FormData = {
  [key: string]: any
}

type Errors = {
  [key: string]: any
}

type props = {
  edit: boolean
  errors: Errors
  handleSubmit: Function
  loading: boolean
  initialFormData: FormData
}

export const SaddlerForm = ({ edit = false, errors, handleSubmit, loading, initialFormData }: props) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => { setFormData(initialFormData) }, [initialFormData])

  const handleActiveToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, ...{ [e.target.name]: e.target.checked } })
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    let updated: any = { [e.target.name]: e.target.value }
    if (e.target.name.indexOf('.') !== -1) {
      const field = e.target.name.split('.')[1]
      updated = { options: { ...formData.options, [field]: e.target.value } }
    }
    setFormData({ ...formData, ...updated })
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(formData) }}>
      <Stack spacing="5" px={{ base: '5', md: '10' }} py={{ base: '5', md: '10' }}>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="first_name" isRequired isInvalid={'first_name' in errors}>
            <FormLabel>First Name</FormLabel>
            <Input type="text" name="first_name" value={formData.first_name ?? ""} onChange={handleChange} />
            {errors.first_name && <FormErrorMessage>{errors?.first_name?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="last_name" isRequired isInvalid={'last_name' in errors}>
            <FormLabel>Last Name</FormLabel>
            <Input type="text" name="last_name" value={formData.last_name ?? ""} onChange={handleChange} />
            {errors.last_name && <FormErrorMessage>{errors?.last_name?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="number" isRequired isInvalid={'number' in errors}>
            <FormLabel>Number</FormLabel>
            <Input type="text" name="number" value={formData.number ?? ""} onChange={handleChange} />
            {errors.number && <FormErrorMessage>{errors?.number?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="active">
            <FormLabel>Active</FormLabel>
            <Switch colorScheme="blue" defaultChecked={!!formData.active} name="active" onChange={handleActiveToggle} />
          </FormControl>
        </Stack>
        <Divider />
        <Flex justifyContent="space-between">
          <Button colorScheme="red" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit" isDisabled={loading}>
          {!loading
            ? 'Submit'
            : (
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='white'
              size='lg'
            />
            )
          }
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}
