import { useEffect, useState } from "react"
import {
  Center,
  Heading,
  Spinner,
  useToast,
} from "@chakra-ui/react"
import { NavLayout } from "../components/NavLayout"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate, useParams } from "react-router-dom"
import { SaddlerForm } from "../components/Forms/SaddlerForm"
import { addServerErrors } from "../services/add-server-errors"

type FormData = {
  first_name: string
  last_name: string
  number: string
  active: string
}

type Errors = {
  [key: string]: { message: string }
}

const initialFormData: FormData = {
  first_name: "",
  last_name: "",
  number: "",
  active: "1",
}

const initialErrors: Errors = {}

export const EditSaddler = () => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const toast = useToast()
  const { saddlerId } = useParams()
  const [data, setData] = useState(initialFormData)
  const [errors, setErrors] = useState(initialErrors)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const endpoint = `/saddlers/edit/${saddlerId}`

  useEffect(() => {
    setLoading(true)
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
        try {
          const response = await axiosPrivate.get(endpoint, {
            signal: controller.signal
          })
          isMounted && setData({ ...response.data.saddler })
          setLoading(false)
        } catch (err: any) {
          console.error(err)
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            isClosable: true,
          })
          navigate(-1)
        }
    }

    const timeout = setTimeout(getData, 500)

    return () => {
        isMounted = false
        isMounted && controller.abort()
        clearTimeout(timeout)
    }
  }, [axiosPrivate, endpoint, navigate, toast])

  const handleSubmit = async (formData: FormData) => {
    setSubmitting(true)
    try {
      const response = await axiosPrivate.post(endpoint, formData)

      if (!response?.data?.saddler?.errors) {
        toast({
          title: 'Success',
          description: 'Saddler successfully updated!',
          status: 'success',
          isClosable: true,
        })
        navigate(-1)
      } else {
        addServerErrors(response?.data?.saddler?.errors, setErrors)
        toast({
          title: 'Error',
          description: 'Please review any errors and resubmit',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
    setSubmitting(false)
  }

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Edit Saddler</Heading>
      }
    >
      {!loading
        ? (
          <SaddlerForm edit={false} errors={errors} handleSubmit={handleSubmit} initialFormData={data} loading={submitting} />
        ) : (
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        )
      }
    </NavLayout>
  )
}
