import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

type props = {
  dataKey: string
  itemId: string | number,
  itemType: string
  refresh: Function
}

export const DeleteModal = ({dataKey, itemId, itemType, refresh}: props) => {
  const axiosPrivate = useAxiosPrivate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const submit = async () => {
    const controller = new AbortController()

    try {
      const response = await axiosPrivate.post(`/${dataKey}/delete/${itemId}`, {
        signal: controller.signal
      })
      refresh()
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err?.message ?? `There was an error deleting this ${itemType}`,
        status: 'error',
        isClosable: true,
      })
    }

    onClose()
  }

  return (
    <>
      <IconButton
        onClick={onOpen}
        variant="solid"
        colorScheme="red"
        aria-label="Delete"
        fontSize="20px"
        icon={<DeleteIcon />}
      />
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete this {itemType}?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Are you sure you would like to delete this {itemType}?</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button colorScheme="red" onClick={submit} ml="5px">Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
