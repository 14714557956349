import { useEffect, useState } from "react"
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { FaInfo, FaInfoCircle, FaPlus, FaTrash } from "react-icons/fa"

type FormData = {
  [key: string]: any
}

type Errors = {
  [key: string]: any
}

type props = {
  edit: boolean
  errors: Errors
  handleSubmit: Function
  loading: boolean
  initialFormData: FormData
}

export const LeadSourceForm = ({ edit = false, errors, handleSubmit, loading, initialFormData }: props) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState(initialFormData)
  const [options, setOptions] = useState({...initialFormData.options})

  useEffect(() => { setFormData(initialFormData) }, [initialFormData])

  const handleToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, ...{ [e.target.name]: e.target.checked } })
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(formData) }}>
      <Stack spacing="5" px={{ base: '5', md: '10' }} py={{ base: '5', md: '10' }}>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
            <FormControl id="name" isRequired isInvalid={'name' in errors}>
                <FormLabel>Name</FormLabel>
                <Input type="text" name="name" value={formData.name ?? ""} onChange={handleChange} />
                {errors.name && <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>}
            </FormControl>
            <FormControl id="active">
                <FormLabel>Active</FormLabel>
                <Switch colorScheme="blue" defaultChecked={!!formData.active} name="active" onChange={handleToggle} />
            </FormControl>
            <Tooltip
                label="If checked, this lead source will include an 'Other' option for users to input a custom value."
                aria-label="A tooltip"
                placement="top"
            >
                <FormControl id="is_other">
                    <FormLabel display="flex">
                        Include Other &nbsp;
                        <FaInfoCircle color=""/>
                    </FormLabel>
                    <Switch colorScheme="blue" defaultChecked={!!formData.is_other} name="is_other" onChange={handleToggle} />
                </FormControl>
            </Tooltip>
        </Stack>
        {!formData.is_other && (
            <>
                <Divider />
                <Stack spacing="5">
                    <Stack spacing="3" direction={{ base: 'column', md: 'row' }}>
                        <Text fontSize="xl" flexGrow="1">Options</Text>
                        <Button
                        size="md"
                        colorScheme="green"
                        onClick={() => {
                            const newOptions = {...options}
                            newOptions[Object.keys(options).length] = ''
                            setOptions(newOptions)
                            setFormData({...formData, options: newOptions})
                        }}
                        >
                        <FaPlus />
                        </Button>
                    </Stack>
                    {Object.entries(options).length === 0 && (
                        <Text fontSize="md" color="gray.500">Additional options supplied to users when this lead source is selected.</Text>
                    )}
                    {options && Object.keys(options).map((key) => {
                        return (
                        <Stack key={key} spacing="3" direction={{ base: 'column', md: 'row' }}>
                            <Input
                            type="text"
                            name={`options.${key}`}
                            value={options[key]}
                            onChange={(e) => {
                                const newOptions = {...options, [key]: e.target.value}
                                setOptions(newOptions)
                                setFormData({...formData, options: newOptions})
                            }}
                            />
                            <Button
                            size="md"
                            colorScheme="red"
                            onClick={() => {
                                const newOptions = {...options}
                                delete newOptions[key]
                                setOptions(newOptions)
                                setFormData({...formData, options: newOptions})
                            }}
                            >
                            <FaTrash />
                            </Button>
                        </Stack>
                        )
                    })}
                </Stack>
            </>
        )}
        <Divider />
        <Flex justifyContent="space-between">
          <Button colorScheme="red" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit" isDisabled={loading}>
          {!loading
            ? 'Submit'
            : (
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='white'
              size='lg'
            />
            )
          }
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}
