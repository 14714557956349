import _ from "lodash"
import {
    Alert,
    Checkbox,
    CheckboxGroup,
    FormControl,
    FormLabel,
    Select,
    Stack,
  } from "@chakra-ui/react"
import { Product } from "../../models/Product"

type FormData = {
    [key: string]: any
}

type props = {
    formData: FormData
    product?: Product | null
    handleChange: (field: string, value: string|number|boolean|(string|number)[]) => void
}

export const OrderProductForm = ({ formData, product, handleChange }: props) => {
    if (!product) {
        return null
    } else if (!product.options) {
        return <Alert status="error">Product Specification Could Not Be Loaded!</Alert>
    }

    return (
        <>
            {product.options.hasOwnProperty('head_type') && (
                <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                    <OptionSelect
                        availableText="Select a Head Type..."
                        accessorKey="head_type"
                        label="Head Type"
                        formData={formData}
                        product={product}
                        handleChange={handleChange}
                    />
                    <FormControl id="order_details.extended_tree_points" paddingTop="7">
                        <Checkbox name="order_details.extended_tree_points"
                            isChecked={formData?.order_details?.extended_tree_points}
                            onChange={(e) => handleChange('extended_tree_points', e.target.checked)}
                        >
                            Extended Tree Points
                        </Checkbox>
                    </FormControl>
                </Stack>
            )}
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Gusset Depth..."
                    accessorKey="gusset_depth"
                    label="Gusset Depth"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Panel Type..."
                    accessorKey="panel_type"
                    label="Panel Types"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                    isRequired={false}
                    multiple
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Seat Size..."
                    accessorKey="seat_size"
                    label="Seat Size"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Tree Size..."
                    accessorKey="tree_size"
                    label="Tree Size"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Color and Type of Leather..."
                    accessorKey="color_and_type_of_leather"
                    label="Color and Type of Leather"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select Billets..."
                    accessorKey="billets"
                    label="Billets"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Flap Position..."
                    accessorKey="flap_position"
                    label="Flap Position"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Flap Length..."
                    accessorKey="flap_length"
                    label="Flap Length"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Calf Roll Type..."
                    accessorKey="calf_roll_type"
                    label="Calf Roll Type"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Calf Roll Size..."
                    accessorKey="calf_roll_size"
                    label="Calf Roll Size"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Knee Roll Type..."
                    accessorKey="knee_roll_type"
                    label="Knee Roll Type"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Knee Roll Size..."
                    accessorKey="knee_roll_size"
                    label="Knee Roll Size"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Girth Color..."
                    accessorKey="girth_color"
                    label="Girth Color"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                    isRequired={false}
                />
                <OptionSelect
                    availableText="Select a Girth Size..."
                    accessorKey="girth_size"
                    label="Girth Size"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                    isRequired={false}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Leathers Color..."
                    accessorKey="leathers_color"
                    label="Leathers Color"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                    isRequired={false}
                />
                <OptionSelect
                    availableText="Select a Leathers Size..."
                    accessorKey="leathers_size"
                    label="Leathers Size"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                    isRequired={false}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Seat Welting Color..."
                    accessorKey="seat_welting"
                    label="Seat Welting"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Cantle Welting Color..."
                    accessorKey="cantle_welting"
                    label="Cantle Welting"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Front Facing Color..."
                    accessorKey="front_facing"
                    label="Front Facing"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Rear Facing Color..."
                    accessorKey="rear_facing"
                    label="Rear Facing"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Keeper Color..."
                    accessorKey="keeper"
                    label="Keeper"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    label="Keeper Style"
                    availableText="Select a Keeper Style..."
                    accessorKey="keeper_style"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                <OptionSelect
                    availableText="Select a Stitching Color..."
                    accessorKey="stitching"
                    label="Stitching"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
                <OptionSelect
                    availableText="Select a Cantlepiece..."
                    accessorKey="cantlepiece"
                    label="Cantlepiece"
                    formData={formData}
                    product={product}
                    handleChange={handleChange}
                />
            </Stack>
            {formData?.order_details?.cantlepiece === 'Full Patent' && (
                <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
                    <OptionSelect
                        availableText="Select a Color..."
                        accessorKey="cantlepiece_full_patent"
                        label="Cantlepiece - Full Patent"
                        formData={formData}
                        product={product}
                        handleChange={handleChange}
                        isRequired={false}
                    />
                </Stack>
            )}
        </>
    )


}

type defaultValueProps = {
    available: boolean
    availableText: string
}

const DefaultValue = ({ available, availableText }: defaultValueProps) => {
    return (
        <option value="">{ available  ? availableText : 'Not available on this model'}</option>
    )
}

type optionSelectProps = {
    availableText: string
    accessorKey: string
    label: string
    formData: FormData
    multiple?: boolean
    isRequired?: boolean
    product: Product
    handleChange: (field: string, value: string|number|boolean|(string|number)[]) => void
}

const OptionSelect = ({ availableText, accessorKey, label, formData, multiple, isRequired, product, handleChange }: optionSelectProps) => {
    if (!product.options) {
        return null
    }
    const dataKey = accessorKey as keyof typeof product.options
    const isValid = !!product.options?.[dataKey]?.length
    const options = product.options?.[dataKey] ?? []

    return (
        <FormControl id={`order_details.${dataKey}`} isRequired={isRequired ?? true}>
            <FormLabel>{label}</FormLabel>
            {!(multiple ?? false) ? (
                <Select
                    name={`order_details.${dataKey}`}
                    value={formData?.order_details[dataKey] ?? ""}
                    onChange={(e) => handleChange(dataKey, e.target.value)}
                    isDisabled={!isValid}
                >
                    <DefaultValue available={isValid} availableText={availableText} />
                    {options.map((o) => <option key={`${dataKey}-${o}`} value={o}>{o}</option>)}
                </Select>
            ) : (
                <CheckboxGroup
                    defaultValue={formData?.order_details[dataKey] ?? []}
                    onChange={(v) => handleChange(dataKey, v)}
                >
                    <Stack direction="row" justifyContent="space-between">
                        {_.chunk(options, 3).map((chunk, i) => (
                            <Stack key={i} direction="column">
                                {chunk.map((o) => <Checkbox key={`${dataKey}-${o}`} value={o}>{o}</Checkbox>)}
                            </Stack>
                        ))}
                    </Stack>
                </CheckboxGroup>
            )}
        </FormControl>
    )
}
