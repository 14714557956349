import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons"
import logo from "../logo.svg"
import axios from "axios"
import useAuth from "../hooks/useAuth"

export const Login = () => {
  const { auth, setAuth } = useAuth()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [show, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/orders'
  const toast = useToast()

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const endpoint = `${process.env.REACT_APP_API_URL}/users/login`
    const response = await axios
      .post(
        endpoint,
        {
          username,
          password,
        },
        {
          timeout: 30000,
          withCredentials: true
        },
      )
      .then((res) => res.data)
      .catch((e) => {
        let message = e.message
        if (e.response?.data?.message) {
          message = e.response.data.message
        }

        toast({
          title: 'Error',
          description: message,
          status: 'error',
          isClosable: true,
        })
      })

    if (response?.token) {
      setAuth({ ...response });
    }
    setLoading(false)
  }

  useEffect(() => {
    if (auth?.token) {
      navigate(from, { replace: true });
    }
  }, [auth, from, navigate])

  return (
    <Box role="main" bgColor="#F7FAFC" textAlign="center" fontSize="xl" h="100vh">
      <VStack justifyContent="center" h="100%">
        <Box bgColor="#D7D5D1" h="200px" w="100%">
          <Center>
            <Image bgColor="#0F2C5A" borderRadius="full" boxSize="350" p="25px" src={logo} marginTop="-75px" alt="County Saddlery Logo"/>
          </Center>
        </Box>
        <Box maxW="md" py={{ base: "12", md: "24" }}>
          <Heading as="h1" m="30px" size="xl">Welcome County Saddlery Agents!</Heading>
          <form onSubmit={login}>
            <FormControl>
              <FormLabel textAlign="left">Username</FormLabel>
              <Input borderColor="black" marginBottom="25px" onChange={u => setUsername(u.target.value)} value={username} />
            </FormControl>
            <FormControl>
              <FormLabel textAlign="left">Password</FormLabel>
              <InputGroup borderColor="black" marginBottom="25px">
                <Input type={show ? "text" : "password"} onChange={p => setPassword(p.target.value)} value={password} />
                <InputRightElement w="10%">
                <IconButton
                  variant="link"
                  aria-label={show ? "Mask password" : "Reveal password"}
                  icon={show ? <ViewOffIcon /> : <ViewIcon />}
                  onClick={() => setShowPassword(!show)}
                />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button bgColor="#0F2C5A" color="#FFFFFF" size="lg" w="100%" type="submit" isDisabled={loading}>
              {!loading
                ? 'Login'
                : (
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='lg'
                  />
                )}
            </Button>
          </form>
        </Box>
      </VStack>
    </Box>
  )
}
