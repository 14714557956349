import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AddIcon, ArrowBackIcon, EditIcon } from "@chakra-ui/icons"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Select,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Saddler } from "../../models/Saddler"
import { LeadSource } from "../../models/LeadSource"
import { Referrer } from "../../models/Referrer"
import useAuth from "../../hooks/useAuth"

type FormData = {
  [key: string]: any
}

type Errors = {
  [key: string]: any
}

type props = {
  errors: Errors
  handleSubmit: Function
  loading: boolean
  initialFormData: FormData
  prevOrderId: number | undefined
  nextOrderId: number | undefined
  formChanged: boolean
  setFormChanged: Function
}

const initialSaddlers: Saddler[] = []

const initialLeadSources: LeadSource[] = []

const initialReferrers: Referrer[] = []

export const OrderEditForm = ({
  errors,
  handleSubmit,
  loading,
  initialFormData,
  prevOrderId,
  nextOrderId,
  formChanged,
  setFormChanged,
}: props) => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  const [saddlers, setSaddlers] = useState(initialSaddlers)
  const [saddlersLoading, setSaddlersLoading] = useState(true)

  const [leadSources, setLeadSources] = useState(initialLeadSources)
  const [leadSourcesLoading, setLeadSourcesLoading] = useState(true)

  const [referrers, setReferrers] = useState(initialReferrers)
  const [refreshReferrers, setRefreshReferrers] = useState(false)

  const [formData, setFormData] = useState(initialFormData)
  const [editDetails, setEditDetails] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [alertCallback, setAlertCallback] = useState<Function>(() => {})
  const cancelRef = useRef<HTMLButtonElement>(null)
  const openAlertDialog = (callback: Function) => {
    setAlertCallback(() => callback)
    onOpen()
  }
  const confirmAlertDialog = () => {
    alertCallback()
    setFormChanged(false)
    setAlertCallback(() => {})
    onClose()
  }
  const formattedDate = (date: Date, timezone: string|undefined) => {
    return new Date(date).toLocaleString('en-US', { timeZone: timezone, dateStyle: 'short' })
  }

  useEffect(() => { setFormData(initialFormData) }, [initialFormData])

  useEffect(() => {
    setSaddlersLoading(true)
    let isMounted = true
    const controller = new AbortController()
    const getData = async () => {
      try {
        const response = await axiosPrivate.get('/saddlers/get-list', {
          signal: controller.signal
        })
        isMounted && setSaddlers(response.data.saddlers)
        setSaddlersLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
    const filterTimeout = setTimeout(getData, 500)
    return () => {
      isMounted = false
      isMounted && controller.abort()
      clearTimeout(filterTimeout)
    }
  }, [axiosPrivate])

  useEffect(() => {
    setLeadSourcesLoading(true)
    let isMounted = true
    const controller = new AbortController()
    const getData = async () => {
      try {
        const response = await axiosPrivate.get('/lead-sources/get-list', {
          signal: controller.signal
        })
        isMounted && setLeadSources(response.data.leadSources)
        setLeadSourcesLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
    const filterTimeout = setTimeout(getData, 500)
    return () => {
      isMounted = false
      isMounted && controller.abort()
      clearTimeout(filterTimeout)
    }
  }, [axiosPrivate])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const getData = async () => {
      try {
        const response = await axiosPrivate.get('/referrers/get-list', {
            signal: controller.signal
        })
        isMounted && setReferrers(response.data.referrers)
      } catch (err) {
        console.error(err)
      }
    }
    const filterTimeout = setTimeout(getData, 5)
    return () => {
      isMounted = false
      isMounted && controller.abort()
      clearTimeout(filterTimeout)
    }
  }, [formData.id, refreshReferrers, axiosPrivate])

  const handleSwitchToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, ...{ [e.target.name]: e.target.checked } })
    setFormChanged(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    let updated: any = { [e.target.name]: e.target.value }
    let field = e.target.name.split('.')[0]
    if (e.target.name.indexOf('.') !== -1) {
      field = e.target.name.split('.')[1]
      updated = { order_details: { ...formData.order_details, [field]: e.target.value } }
    }
    setFormData({ ...formData, ...updated })
    setFormChanged(true)
  }

  const handleOrderNavigation = (id: number|undefined) => {
    if (formChanged) {
      openAlertDialog(() => {
        if (id) {
          navigate(`/orders/edit/${id}`)
          window.scrollTo(0, 0)
        }
      })
    } else {
      if (id) {
        navigate(`/orders/edit/${id}`)
        window.scrollTo(0, 0)
      }
    }
  }

  const handleCancel = () => {
    if (formChanged) {
      openAlertDialog(() => {
        navigate(-1)
      });
    } else {
      navigate(-1)
    }
  }

  if (!formData.id || saddlersLoading || leadSourcesLoading) {
    return (
      <Center>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Center>
    )
  }

  const leadSource = leadSources.find((l: LeadSource) => l.id === parseInt(formData.lead_source_id))
  const leadSourceOptions = leadSource?.options

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await handleSubmit(formData);
        setRefreshReferrers(!refreshReferrers);
      }}
    >
        <Stack spacing="5" px={{ base: '3', xl: '5' }} py={{ base: '3'}} w="100%">
            <Heading as="h2" size="lg">Order Details</Heading>
            <Stack spacing="8" direction={{ base: 'column', xl: 'row' }} w="100%">
                <Box w="100%">
                  <TableContainer>
                    <Table sx={{
                      '& th, & td': {
                        verticalAlign: 'top',
                        padding: '1rem',
                      },
                      '& tbody > tr:last-child th': {
                        border: 0,
                      },
                      '& tbody > tr:last-child td': {
                        border: 0,
                      },
                    }}>
                      <Tbody>
                        <Tr>
                          <Th>
                            Order #
                          </Th>
                          <Td>
                            {`${formData?.order_number ?? ""}`}
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>
                            Order Date
                          </Th>
                          <Td>
                            {`${formData?.created ? formattedDate(formData.created, auth?.user?.timezone) : ""}`}
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>
                            Customer
                          </Th>
                          <Td>
                            {`${formData?.customer?.first_name ?? ""} ${formData?.customer?.last_name ?? ""}`}
                          </Td>
                        </Tr>
                        {formData?.customer?.phone && (
                          <Tr>
                            <Th>
                              Customer Phone
                            </Th>
                            <Td>
                              {formData?.customer?.phone}
                            </Td>
                          </Tr>
                        )}
                        {formData?.customer?.email && (
                          <Tr>
                            <Th>
                              Customer Email
                            </Th>
                            <Td>
                              {formData?.customer?.email}
                            </Td>
                          </Tr>
                        )}
                        {formData?.customer?.street_address_1 && (
                          <Tr>
                            <Th>
                              Customer Address
                            </Th>
                            <Td>
                              {[
                                formData?.customer?.street_address_1,
                                formData?.customer?.street_address_2,
                              ].filter(Boolean).join(', ')}<br />
                              {formData?.customer?.city}, {formData?.customer?.state} {formData?.customer?.postal_code}<br />
                              {formData?.customer?.country}
                            </Td>
                          </Tr>
                        )}
                        <Tr>
                          <Th>
                            Product
                          </Th>
                          <Td>
                            {formData?.order_details?.product_name}
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>
                            Specification
                          </Th>
                          <Td sx={{ position: 'relative' }}>
                            <Box>
                            <SpecEntry spec="head_type" label="Head Type" orderDetails={formData.order_details} />
                            <SpecEntry spec="extended_tree_points" label="Extended Tree Points" orderDetails={formData.order_details} />
                            <SpecEntry spec="seat_size" label="Seat Size" orderDetails={formData.order_details} />
                            <SpecEntry spec="tree_size" label="Tree Size" orderDetails={formData.order_details} />
                            <SpecEntry spec="product_code" label="Product Code" orderDetails={formData.order_details} />
                            <SpecEntry spec="color_and_type_of_leather" label="Color and Type of Leather" orderDetails={formData.order_details} />
                            <SpecEntry spec="flap_position" label="Flap Position" orderDetails={formData.order_details} />
                            <SpecEntry spec="flap_length" label="Flap Length" orderDetails={formData.order_details} />
                            <SpecEntry spec="knee_roll_type" label="Knee Roll Type" orderDetails={formData.order_details} />
                            <SpecEntry spec="knee_roll_size" label="Knee Roll Size" orderDetails={formData.order_details} />
                            <SpecEntry spec="calf_roll_type" label="Calf Roll Type" orderDetails={formData.order_details} />
                            <SpecEntry spec="calf_roll_size" label="Calf Roll Size" orderDetails={formData.order_details} />
                            <SpecEntry spec="panel_type" label="Panel Types" orderDetails={formData.order_details} />
                            <SpecEntry spec="gusset_depth" label="Gusset Depth" orderDetails={formData.order_details} />
                            <SpecEntry spec="billets" label="Billets" orderDetails={formData.order_details} />
                            <SpecEntry spec="girth_color" label="Girth Color" orderDetails={formData.order_details} />
                            <SpecEntry spec="girth_size" label="Girth Size" orderDetails={formData.order_details} />
                            <SpecEntry spec="leathers_color" label="Leathers Color" orderDetails={formData.order_details} />
                            <SpecEntry spec="leathers_size" label="Leathers Size" orderDetails={formData.order_details} />
                            <SpecEntry spec="seat_welting" label="Seat Welting" orderDetails={formData.order_details} />
                            <SpecEntry spec="cantle_welting" label="Cantle Welting" orderDetails={formData.order_details} />
                            <SpecEntry spec="front_facing" label="Front Facing" orderDetails={formData.order_details} />
                            <SpecEntry spec="rear_facing" label="Rear Facing" orderDetails={formData.order_details} />
                            <SpecEntry spec="keeper" label="Keeper" orderDetails={formData.order_details} />
                            <SpecEntry spec="keeper_style" label="Keeper Style" orderDetails={formData.order_details} />
                            <SpecEntry spec="cantlepiece" label="Cantlepiece" orderDetails={formData.order_details} />
                            <SpecEntry spec="cantlepiece_full_patent" label="Cantlepiece - Full Patent" orderDetails={formData.order_details} />
                            <SpecEntry spec="stitching" label="Stitching" orderDetails={formData.order_details} />
                            </Box>
                            <IconButton
                              onClick={() => setEditDetails(!editDetails)}
                              aria-label="Edit"
                              icon={<EditIcon />}
                              variant="outline"
                              sx={{
                                position: 'absolute',
                                top: '3',
                                right: '0',
                              }}
                            />
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>
                            User/Rep
                          </Th>
                          <Td>
                          {`${formData?.user?.first_name ?? ""} ${formData?.user?.last_name ?? ""}`}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
                <Stack spacing="4" w="100%">
                  <HStack spacing="4">
                    <FormControl id="job_ticket" isInvalid={'job_ticket' in errors}>
                      <FormLabel>Job Ticket</FormLabel>
                      <Input type="text" name="job_ticket" value={formData.job_ticket ?? ""} onChange={handleChange} />
                      {errors.options && <FormErrorMessage>{errors?.options?.job_ticket?.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl id="saddler_id" isInvalid={'saddler_id' in errors}>
                      <FormLabel>Saddler</FormLabel>
                      <HStack>
                        <Select
                          name="saddler_id"
                          value={formData.saddler_id ?? ""}
                          onChange={(e) => setFormData({...formData, saddler_id: e.target.value})}
                        >
                          <option value=""></option>
                          {saddlers.map((l: Saddler) => <option key={`saddler-${l.id}`} value={l.id}>{l.name}</option>)}
                        </Select>
                        {errors.saddler_id && <FormErrorMessage>{errors?.saddler_id?.message}</FormErrorMessage>}
                      </HStack>
                    </FormControl>
                    <FormControl id="serial_number" isInvalid={'serial_number' in errors}>
                      <FormLabel>Serial #</FormLabel>
                      <Input type="text" name="serial_number" value={formData.serial_number ?? ""} onChange={handleChange} />
                      {errors.options && <FormErrorMessage>{errors?.options?.serial_number?.message}</FormErrorMessage>}
                    </FormControl>
                  </HStack>
                  <HStack spacing="4">
                    <FormControl id="estimated_ship_date" isInvalid={'estimated_ship_date' in errors}>
                      <FormLabel>Estimated Ship Date</FormLabel>
                      <Input type="date" name="estimated_ship_date" value={formData.estimated_ship_date ?? ""} onChange={handleChange} />
                      {errors.options && <FormErrorMessage>{errors?.options?.estimated_ship_date?.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl id="actual_ship_date" isInvalid={'actual_ship_date' in errors}>
                      <FormLabel>Actual Ship Date</FormLabel>
                      <Input type="date" name="actual_ship_date" value={formData.actual_ship_date ?? ""} onChange={handleChange} />
                      {errors.options && <FormErrorMessage>{errors?.options?.actual_ship_date?.message}</FormErrorMessage>}
                    </FormControl>
                  </HStack>
                  <FormControl id="tracking_url" isInvalid={'tracking_url' in errors}>
                    <FormLabel>Tracking URL</FormLabel>
                    <Input type="text" name="tracking_url" value={formData.tracking_url ?? ""} onChange={handleChange} />
                    {errors.options && <FormErrorMessage>{errors?.options?.tracking_url?.message}</FormErrorMessage>}
                  </FormControl>
                  <Stack spacing="8" direction={{ base: 'column', md: 'row' }} w="100%">
                    <FormControl id="lead_source_id" isRequired isInvalid={'lead_source_id' in errors}>
                      <FormLabel>Lead Source</FormLabel>
                      <HStack>
                        <Select
                          name="lead_source_id"
                          value={formData.lead_source_id}
                          onChange={(e) => setFormData({
                            ...formData,
                            lead_source_id: e.target.value,
                            lead_source_other: '',
                          })}
                        >
                          <option value="">Select a Lead Source...</option>
                          {leadSources.map((l: LeadSource) => <option key={`lead-source-${l.id}`} value={l.id}>{l.name}</option>)}
                        </Select>
                        {errors.lead_source_id && <FormErrorMessage>{errors?.lead_source_id?.message}</FormErrorMessage>}
                      </HStack>
                    </FormControl>
                    {leadSource && leadSource.is_other && (
                      <FormControl id="lead_source_other" isRequired isInvalid={'lead_source_other' in errors}>
                        <FormLabel>Lead Source Other</FormLabel>
                        <HStack>
                          <Input
                            name="lead_source_other"
                            value={formData.lead_source_other}
                            onChange={(e) => setFormData({...formData, lead_source_other: e.target.value})}
                          />
                          {errors.lead_source_other && <FormErrorMessage>{errors?.lead_source_other?.message}</FormErrorMessage>}
                        </HStack>
                      </FormControl>
                    )}
                    {leadSource && !leadSource.is_other && leadSourceOptions && (
                      <FormControl id="lead_source_other" isRequired isInvalid={'lead_source_other' in errors}>
                        <FormLabel>Lead Source Option</FormLabel>
                        <HStack>
                          <Select
                            name="lead_source_other"
                            value={formData.lead_source_other}
                            onChange={(e) => setFormData({...formData, lead_source_other: e.target.value})}
                          >
                            <option value="">Select a Lead Source Option...</option>
                            {leadSourceOptions.map((o: string) => <option key={`lead-source-option-${o}`} value={o}>{o}</option>)}
                          </Select>
                          {errors.lead_source_other && <FormErrorMessage>{errors?.lead_source_other?.message}</FormErrorMessage>}
                        </HStack>
                      </FormControl>
                    )}
                  </Stack>
                  <Stack spacing="8" direction={{ base: 'column', md: 'row' }} w="100%">
                    <FormControl id="referrer_id" isInvalid={'referrer_id' in errors}>
                        <FormLabel>Referred By</FormLabel>
                          {formData?.new_referrer !== undefined && (
                            <HStack w="100%">
                              <Stack w="100%">
                                <Input
                                  name="new_referrer"
                                  value={formData.new_referrer ?? ''}
                                  onChange={(e) => setFormData({
                                    ...formData,
                                    new_referrer: e.target.value,
                                  })}
                                  placeholder="Enter the name of the referrer..."
                                />
                                {errors.new_referrer && <FormErrorMessage>{errors?.new_referrer?.message}</FormErrorMessage>}
                              </Stack>
                              <Tooltip label="Back">
                                <IconButton
                                  variant="solid"
                                  colorScheme="green"
                                  aria-label="Back"
                                  fontSize="20px"
                                  icon={<ArrowBackIcon />}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      new_referrer: undefined,
                                    })
                                  }}
                                />
                              </Tooltip>
                            </HStack>
                          )}
                          {formData?.new_referrer === undefined && (
                            <HStack w="100%">
                              <Stack w="100%">
                                <Select
                                  name="referrer_id"
                                  value={formData.referrer_id ?? ''}
                                  onChange={(e) => setFormData({
                                    ...formData,
                                    referrer_id: e.target.value,
                                    new_referrer: undefined,
                                  })}
                                >
                                  <option value="">Select a Referrer...</option>
                                  {referrers.map((r: Referrer) => <option key={`referrer-${r.id}`} value={r.id}>{r.name}</option>)}
                                </Select>
                                {errors.referrer_id && <FormErrorMessage>{errors?.referrer_id?.message}</FormErrorMessage>}
                              </Stack>
                              <Tooltip label="Add New Referrer">
                                <IconButton
                                  variant="solid"
                                  colorScheme="green"
                                  aria-label="Add Referrer"
                                  fontSize="20px"
                                  icon={<AddIcon />}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      new_referrer: '',
                                    })
                                  }}
                                />
                              </Tooltip>
                            </HStack>
                          )}
                    </FormControl>
                  </Stack>
                  <FormControl id="notes" isInvalid={'notes' in errors}>
                    <FormLabel>Notes</FormLabel>
                    <HStack>
                      <Textarea name="notes" value={formData.notes ?? ''} onChange={handleChange} placeholder="Enter any notes about the order..." />
                      {errors.notes && <FormErrorMessage>{errors?.notes?.message}</FormErrorMessage>}
                    </HStack>
                  </FormControl>
                  <FormControl id="special_instructions" isInvalid={'special_instructions' in errors}>
                    <FormLabel>Special Instructions</FormLabel>
                    <HStack>
                      <Textarea name="special_instructions" value={formData.special_instructions ?? ''} onChange={handleChange} placeholder="Enter any special instructions for the order..." />
                      {errors.special_instructions && <FormErrorMessage>{errors?.special_instructions?.message}</FormErrorMessage>}
                    </HStack>
                  </FormControl>
                  <FormControl id="paperwork_received">
                    <HStack spacing="4">
                      <Switch colorScheme="blue" isChecked={!!formData.paperwork_received} name="paperwork_received" onChange={handleSwitchToggle} />
                      <FormLabel>Paperwork Received</FormLabel>
                    </HStack>
                  </FormControl>
                  <FormControl id="archived">
                    <HStack spacing="4">
                      <Switch colorScheme="red" isChecked={!!formData.archived} name="archived" onChange={handleSwitchToggle} />
                      <FormLabel>Archived</FormLabel>
                    </HStack>
                  </FormControl>
                </Stack>
            </Stack>
            <Divider />
            {editDetails && (
              <>
                <Stack spacing="4" w="100%" py="4">
                  <Heading as="h3" size="md">Edit Specifications</Heading>
                  <HStack
                    spacing="0"
                    justify="space-between"
                    wrap="wrap"
                    sx={{
                      '& > div': {
                        width: '32%',
                        pb: '4',

                      }
                    }}
                  >
                    {Object.keys(formData.order_details).map((key) => (
                      key !== 'product_name' && (
                        <Box key={key}>
                          <FormControl id={key}>
                            <FormLabel>
                              {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                            </FormLabel>
                            <Input
                              type="text"
                              name={`order_details.${key}`}
                              value={formData.order_details[key] ?? ''}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </Box>
                      )
                    ))}
                  </HStack>
                </Stack>
                <Divider />
              </>
            )}
            <HStack justifyContent="space-between">
              <HStack>
                <Button
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => handleOrderNavigation(prevOrderId)}
                  disabled={!prevOrderId}
                  isDisabled={!prevOrderId}
                >
                  Prev
                </Button>
                <Button
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => handleOrderNavigation(nextOrderId)}
                  disabled={!nextOrderId}
                  isDisabled={!nextOrderId}
                >
                  Next
                </Button>
              </HStack>
              <HStack>
                <Button colorScheme="red" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button colorScheme="blue" type="submit" isDisabled={loading}>
                {!loading
                    ? 'Submit'
                    : (
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='white'
                            size='lg'
                        />
                    )
                }
                </Button>
              </HStack>
            </HStack>
        </Stack>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Unsaved Changes
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure? You have unsaved changes that will be lost.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={confirmAlertDialog} ml={3}>
                  Continue
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
    </form>
  )
}

type specEntryProps = {
  spec: string
  label: string
  orderDetails: { [key: string]: string|number|boolean|(string|number)[] }
}

const SpecEntry = ({ spec, label, orderDetails }: specEntryProps) => {
  if (!orderDetails) return null
  if (!orderDetails[spec]) return null

  let value = <Text>{orderDetails[spec]}</Text>

  if (typeof orderDetails[spec] == 'boolean') {
    value = <Text>{orderDetails[spec] ? 'Yes' : 'No'}</Text>
  }

  if (orderDetails[spec] instanceof Array) {
    value = <>{(orderDetails[spec] as string[]).map(v => (
      <Tag key={v}>
        {v}
      </Tag>
    ))}</>
  }

  return (
    <HStack>
      <Text fontWeight="bold">{label}: </Text>
      {value}
    </HStack>
  )
}
