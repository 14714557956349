import { useMemo } from "react"
import {
  Button,
  Heading,
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { ColumnDef } from "@tanstack/react-table"
import { AddIcon } from "@chakra-ui/icons"
import { PaginatedTable } from "../components/PaginatedTable"
import { Customer } from "../models/Customer"
import { NavLayout } from "../components/NavLayout"

export const Customers = () => {
  const columns = useMemo<ColumnDef<Customer>[]>(
    () => [
      {
        accessorKey: 'id',
        id: 'id'
      },
      {
        accessorKey: 'first_name',
        header: () => 'First Name',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'last_name',
        header: () => 'Last Name',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'phone',
        header: () => 'Phone',
        footer: props => props.column.id,
      },
      {
        accessorKey: 'email',
        header: () => 'Email',
        footer: props => props.column.id,
      },
    ],
    []
  )

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Customers</Heading>
      }
      right={
        <Button
          as={ReactRouterLink}
          to="/customers/add"
          boxShadow="dark-lg"
          variant="solid"
          colorScheme="green"
          aria-label="Add Customer"
          fontSize="20px"
          leftIcon={<AddIcon />}
        >
          Add Customer
        </Button>
      }
    >
      <PaginatedTable<Customer> dataKey="customers" singular="Customer" endpoint="/customers/index" columns={columns} />
    </NavLayout>
  )
}
