import { useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  VStack,
} from "@chakra-ui/react"
import logo from "../logo.svg"

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Box role="main" bgColor="#F7FAFC" textAlign="center" fontSize="xl" h="100vh">
      <VStack justifyContent="center" h="100%">
        <Box bgColor="#D7D5D1" h="200px" w="100%">
          <Center>
            <Image bgColor="#0F2C5A" borderRadius="full" boxSize="350" p="25px" src={logo} marginTop="-75px" alt="County Saddlery Logo"/>
          </Center>
        </Box>
        <Box maxW="md" py={{ base: "12", md: "24" }}>
          <Heading as="h1" m="30px" size="xl">Whoops! How did you end up here?</Heading>
          <Button bgColor="#0F2C5A" color="#FFFFFF" size="lg" w="100%" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Box>
      </VStack>
    </Box>
  )
}
