import { ReactElement } from "react"
import { Box, Card, CardBody, Container, Flex, Spacer } from "@chakra-ui/react"
import { Nav } from "./Nav"

type props = {
  left?: ReactElement
  right?: ReactElement
  children?: ReactElement | ReactElement[]
}

export const NavLayout = ({ left, right, children }: props) => {
  return (
    <Box role="main">
      <Nav/>
      <Container
        bgColor="#F7FAFC"
        maxW="full"
        minH="100vh"
        centerContent
        pt={["140px", null, null, "120px"]}
        px={{ sm: "2%", md: "2%" }}
        mb={10}
      >
        <Flex justifyContent="space-evenly" mb="20px" w="100%">
          {left}
          <Spacer />
          {right}
        </Flex>
        <Card w="100%" boxShadow="lg">
          <CardBody px={["0px", null, null, "2%"]}>
            { children }
          </CardBody>
        </Card>
      </Container>
    </Box>
  )
}
