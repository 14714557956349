import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

type FormData = {
  [key: string]: any
}

type Errors = {
  [key: string]: any
}

type props = {
  errors: Errors
  handleSubmit: Function
  loading: boolean
  initialFormData: FormData
}

export const ProductForm = ({ errors, handleSubmit, loading, initialFormData }: props) => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => { setFormData(initialFormData) }, [initialFormData])

  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(true)

  useEffect(() => {
    setUsersLoading(true)
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
      try {
        const response = await axiosPrivate.get('/users/index?limit=9999', {
          signal: controller.signal
        })
        isMounted && setUsers(response.data.users)
        setUsersLoading(false)
      } catch (err) {
        console.error(err)
      }
    }

    const filterTimeout = setTimeout(getData, 500)

    return () => {
      isMounted = false
      isMounted && controller.abort()
      clearTimeout(filterTimeout)
    }
  }, [axiosPrivate])

  const handleActiveToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, ...{ [e.target.name]: e.target.checked } })
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    let updated: any = { [e.target.name]: e.target.value }
    if (e.target.name.indexOf('.') !== -1) {
      const field = e.target.name.split('.')[1]
      updated = { options: { ...formData.options, [field]: e.target.value } }
    }
    setFormData({ ...formData, ...updated })
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(formData) }}>
      <Stack spacing="5" px={{ base: '5' }} py={{ base: '5' }}>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="name" isRequired isInvalid={'name' in errors}>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={formData.name ?? ""} onChange={handleChange} />
            {errors.name && <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Alert>
          Enter values separated by a comma to create a list of options for each field. Example: Seat Sizes - 15, 15.5, 16,...
        </Alert>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.seat_size" isRequired isInvalid={'seat_size' in errors}>
            <FormLabel>Seat Sizes</FormLabel>
            <Input type="text" name="options.seat_size" value={formData.options.seat_size ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.seat_size?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.tree_size" isRequired isInvalid={'tree_size' in errors}>
            <FormLabel>Tree Sizes</FormLabel>
            <Input type="text" name="options.tree_size" value={formData.options.tree_size ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.tree_size?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.color_and_type_of_leather" isRequired isInvalid={'color_and_type_of_leather' in errors}>
            <FormLabel>Colors and Types of Leather</FormLabel>
            <Input type="text" name="options.color_and_type_of_leather" value={formData.options.color_and_type_of_leather ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.color_and_type_of_leather?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.head_type" isInvalid={'head_type' in errors}>
            <FormLabel>Head Types</FormLabel>
            <Input type="text" name="options.head_type" value={formData.options.head_type ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.head_type?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.flap_position" isRequired isInvalid={'flap_position' in errors}>
            <FormLabel>Flap Positions</FormLabel>
            <Input type="text" name="options.flap_position" value={formData.options.flap_position ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.flap_position?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.flap_length" isInvalid={'flap_length' in errors}>
            <FormLabel>Flap Lengths</FormLabel>
            <Input type="text" name="options.flap_length" value={formData.options.flap_length ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.flap_length?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.knee_roll_type" isInvalid={'knee_roll_type' in errors}>
            <FormLabel>Knee Roll Types</FormLabel>
            <Input type="text" name="options.knee_roll_type" value={formData.options.knee_roll_type ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.knee_roll_type?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.knee_roll_size" isInvalid={'knee_roll_size' in errors}>
            <FormLabel>Knee Roll Sizes</FormLabel>
            <Input type="text" name="options.knee_roll_size" value={formData.options.knee_roll_size ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.knee_roll_size?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.calf_roll_type" isInvalid={'calf_roll_type' in errors}>
            <FormLabel>Calf Roll Types</FormLabel>
            <Input type="text" name="options.calf_roll_type" value={formData.options.calf_roll_type ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.calf_roll_type?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.calf_roll_size" isInvalid={'calf_roll_size' in errors}>
            <FormLabel>Calf Roll Sizes</FormLabel>
            <Input type="text" name="options.calf_roll_size" value={formData.options.calf_roll_size ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.calf_roll_size?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.gusset_depth" isRequired isInvalid={'gusset_depth' in errors}>
            <FormLabel>Gusset Depths</FormLabel>
            <Input type="text" name="options.gusset_depth" value={formData.options.gusset_depth ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.gusset_depth?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.billets" isInvalid={'billets' in errors}>
            <FormLabel>Billets</FormLabel>
            <Input type="text" name="options.billets" value={formData.options.billets ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.billets?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.girth_color" isInvalid={'girth_color' in errors}>
            <FormLabel>Girth Colors</FormLabel>
            <Input type="text" name="options.girth_color" value={formData.options.girth_color ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.girth_color?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.girth_size" isInvalid={'girth_size' in errors}>
            <FormLabel>Girth Sizes</FormLabel>
            <Input type="text" name="options.girth_size" value={formData.options.girth_size ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.girth_size?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.leathers_color" isInvalid={'leathers_color' in errors}>
            <FormLabel>Leathers Colors</FormLabel>
            <Input type="text" name="options.leathers_color" value={formData.options.leathers_color ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.leathers_color?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.leathers_size" isInvalid={'leathers_size' in errors}>
            <FormLabel>Leathers Sizes</FormLabel>
            <Input type="text" name="options.leathers_size" value={formData.options.leathers_size ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.leathers_size?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.panel_type" isRequired isInvalid={'panel_type' in errors}>
            <FormLabel>Panel Types</FormLabel>
            <Input type="text" name="options.panel_type" value={formData.options.panel_type ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.panel_type?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.stitching" isRequired isInvalid={'stitching' in errors}>
            <FormLabel>Stitching</FormLabel>
            <Input type="text" name="options.stitching" value={formData.options.stitching ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.stitching?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.seat_welting" isRequired isInvalid={'seat_welting' in errors}>
            <FormLabel>Seat Welting</FormLabel>
            <Input type="text" name="options.seat_welting" value={formData.options.seat_welting ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.seat_welting?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.cantle_welting" isInvalid={'cantle_welting' in errors}>
            <FormLabel>Cantle Welting</FormLabel>
            <Input type="text" name="options.cantle_welting" value={formData.options.cantle_welting ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.cantle_welting?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.front_facing" isRequired isInvalid={'front_facing' in errors}>
            <FormLabel>Front Facing</FormLabel>
            <Input type="text" name="options.front_facing" value={formData.options.front_facing ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.front_facing?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.rear_facing" isRequired isInvalid={'rear_facing' in errors}>
            <FormLabel>Rear Facing</FormLabel>
            <Input type="text" name="options.rear_facing" value={formData.options.rear_facing ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.rear_facing?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.keeper" isRequired isInvalid={'keeper' in errors}>
            <FormLabel>Keeper Color</FormLabel>
            <Input type="text" name="options.keeper" value={formData.options.keeper ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.keeper?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.keeper_style" isRequired isInvalid={'keeper_style' in errors}>
            <FormLabel>Keeper Style</FormLabel>
            <Input type="text" name="options.keeper_style" value={formData.options.keeper_style ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.keeper_style?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="options.cantlepiece" isInvalid={'cantlepiece' in errors}>
            <FormLabel>Cantlepiece</FormLabel>
            <Input type="text" name="options.cantlepiece" value={formData.options.cantlepiece ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.cantlepiece?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl id="options.cantlepiece_full_patent" isInvalid={'cantlepiece_full_patent' in errors}>
            <FormLabel>Cantlepiece - Full Patent</FormLabel>
            <Input type="text" name="options.cantlepiece_full_patent" value={formData.options.cantlepiece_full_patent ?? ""} onChange={handleChange} />
            {errors.options && <FormErrorMessage>{errors?.options?.cantlepiece_full_patent?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Divider />
        <Alert>
          Select all users that are not authorized to purchase this product.
        </Alert>
        <CheckboxGroup colorScheme="blue" defaultValue={formData.unauthorized_user_ids} onChange={(values) => setFormData({ ...formData, unauthorized_user_ids: values })}>
          <SimpleGrid columns={{sm: 2, md: 3, lg: 5}} spacing={5}>
            {users.map((user: any) => (
              <Checkbox key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
              </Checkbox>
            ))}
          </SimpleGrid>
        </CheckboxGroup>
        <Divider />
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
          <FormControl id="active">
            <FormLabel>Active</FormLabel>
            <Switch colorScheme="blue" defaultChecked={!!formData.active} name="active" onChange={handleActiveToggle} />
          </FormControl>
        </Stack>
        <Divider />
        <Flex justifyContent="space-between">
          <Button colorScheme="red" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit" isDisabled={loading}>
          {!loading
            ? 'Submit'
            : (
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='white'
              size='lg'
            />
            )
          }
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}
