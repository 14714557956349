import React, { createContext, Dispatch, SetStateAction, useState } from "react"

interface AuthContextType {
    auth: {
        token: string,
        user?: {
            id: string
            timezone: string
            role?: number
        }
        roles: Object
    },
    setAuth: Dispatch<SetStateAction<{ token: string, roles: Object }>>
}

const AuthContext = createContext<AuthContextType>({
    auth: {
        token: '',
        roles: {}
    },
    setAuth: () => console.log('Attempting to use AuthContext outside of valid provider')
})

type props = {
    children: React.ReactElement,
}

export const AuthProvider = ({ children }: props) => {
    const [auth, setAuth] = useState({ token: '', roles: {} })

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
