import { Routes, Route } from "react-router-dom"
import { AuthRoute } from "./components/AuthRoute"
import { Login } from "./pages/Login"
import { NotFound } from "./pages/NotFound"
import { Customers } from "./pages/Customers"
import { AddCustomer } from "./pages/AddCustomer"
import { EditCustomer } from "./pages/EditCustomer"
import { Users } from "./pages/Users"
import { AddUser } from "./pages/AddUser"
import { EditUser } from "./pages/EditUser"
import { Orders } from "./pages/Orders"
import { AddOrder } from "./pages/AddOrder"
import { EditOrder } from "./pages/EditOrder"
import { Products } from "./pages/Products"
import { AddProduct } from "./pages/AddProduct"
import { EditProduct } from "./pages/EditProduct"
import { EditProductCodes } from "./pages/EditProductCodes"
import { LeadSources } from "./pages/LeadSources"
import { AddLeadSource } from "./pages/AddLeadSource"
import { EditLeadSource } from "./pages/EditLeadSource"
import { Saddlers } from "./pages/Saddlers"
import PersistLogin from "./components/PersistLogin"
import useAuth from "./hooks/useAuth"
import { AddSaddler } from "./pages/AddSaddler"
import { EditSaddler } from "./pages/EditSaddler"

export const App = () => {
  const { auth } = useAuth()

  const Roles: any = auth?.roles

  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route path="/" element={<Login />} />
        <Route path='*' element={<NotFound />}/>
        <Route element={<AuthRoute allowedRoles={[Roles?.Super, Roles?.Admin, Roles?.User]} />}>
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/add" element={<AddCustomer />} />
          <Route path="/customers/edit/:customerId" element={<EditCustomer />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/add" element={<AddOrder />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={[Roles?.Super, Roles?.Admin]} />}>
          <Route path="/orders/edit/:orderId" element={<EditOrder />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:userId" element={<EditUser />} />
          <Route path="/saddlers" element={<Saddlers />} />
          <Route path="/saddlers/add" element={<AddSaddler />} />
          <Route path="/saddlers/edit/:saddlerId" element={<EditSaddler />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:productId" element={<EditProduct />} />
          <Route path="/products/codes/:productId" element={<EditProductCodes />} />
          <Route path="/lead-sources" element={<LeadSources />} />
          <Route path="/lead-sources/add" element={<AddLeadSource/>} />
          <Route path="/lead-sources/edit/:leadSourceId" element={<EditLeadSource />} />
        </Route>
      </Route>
    </Routes>
  )
}
