import { useState } from "react"
import {
  Heading,
  useToast,
} from "@chakra-ui/react"
import { NavLayout } from "../components/NavLayout";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { addServerErrors } from "../services/add-server-errors";
import { OrderAddForm } from "../components/Forms/OrderAddForm";
import useAuth from "../hooks/useAuth";

type FormData = {
  customer_id: { [key: string]: any }
  product_id: string
  order_details?: {
    seat_size: string
    tree_size: string
    color_and_type_of_leather: string
    flap_position: string
    flap_length: string
    calf_roll_type: string
    calf_roll_size: string
    knee_roll_type: string
    knee_roll_size: string
    panel_type: string
    gusset_depth: string
    billet: string
    girth_size: string
    girth_color: string
    leather_size: string
    leather_color: string
    head_type?: string
  },
  referrer_id?: string|number|null,
  new_referrer?: string|null
};

type Errors = {
  [key: string]: { message: string }
}

const initialFormData: FormData = {
  customer_id: {},
  product_id: "",
};

const initialErrors: Errors = {}

export const AddOrder = () => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const toast = useToast()
  const [errors, setErrors] = useState(initialErrors)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (formData: FormData) => {
    setLoading(true)
    try {
      if (!formData?.customer_id?.id || formData?.customer_id?.id === '') {
        toast({
          title: 'Error',
          description: 'Please choose a customer',
          status: 'error',
          isClosable: true,
        })

        setLoading(false)

        return
      }
      const data = {
        ...formData,
        user_id: auth?.user?.id,
        customer_id: formData?.customer_id?.id
      }
      const response = await axiosPrivate.post('/orders/add', data)

      if (!response?.data?.order?.errors) {
        toast({
          title: 'Success',
          description: 'Order added successfully!',
          status: 'success',
          isClosable: true,
        })
        navigate(-1)
      } else {
        addServerErrors(response?.data?.order?.errors, setErrors)
        toast({
          title: 'Error',
          description: 'Please review any errors and resubmit',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
    setLoading(false)
  }

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Add Order</Heading>
      }
    >
      <OrderAddForm
        errors={errors}
        handleSubmit={handleSubmit}
        initialFormData={initialFormData}
        loading={loading}
      />
    </NavLayout>
  )
}
