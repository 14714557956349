import { useMemo } from "react"
import {
  Button,
  Heading,
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { ColumnDef } from "@tanstack/react-table"
import { AddIcon } from "@chakra-ui/icons"
import { PaginatedTable } from "../components/PaginatedTable"
import { LeadSource } from "../models/LeadSource"
import { NavLayout } from "../components/NavLayout"

export const LeadSources = () => {
  const columns = useMemo<ColumnDef<LeadSource>[]>(
    () => [
      {
        accessorKey: 'id',
        id: 'id'
      },
      {
        accessorKey: 'name',
        header: () => 'Name',
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.active ? 'Yes' : 'No',
        accessorKey: 'active',
        header: () => 'Active',
        footer: props => props.column.id,
      }
    ],
    []
  )

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Lead Sources</Heading>
      }
      right={
        <Button
          as={ReactRouterLink}
          to="/lead-sources/add"
          boxShadow="dark-lg"
          variant="solid"
          colorScheme="green"
          aria-label="Add LeadSource"
          fontSize="20px"
          leftIcon={<AddIcon />}
        >
          Add Lead Source
        </Button>
      }
    >
      <PaginatedTable<LeadSource> dataKey="leadSources" altRouteAccessor="lead-sources" singular="Lead Source" endpoint="/lead-sources/index" columns={columns} />
    </NavLayout>
  )
}
