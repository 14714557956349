import { useEffect, useState } from "react"
import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import AsyncSelect from "react-select/async"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Customer } from "../../models/Customer"
import { Product } from "../../models/Product"
import { useNavigate } from "react-router-dom"
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons"
import { CustomerForm } from "./CustomerForm"
import { addServerErrors } from "../../services/add-server-errors"
import { OrderProductForm } from "./OrderProductForm"
import { LeadSource } from "../../models/LeadSource"
import { Referrer } from "../../models/Referrer"

type FormData = {
  [key: string]: any
}

type Errors = {
  [key: string]: any
}

type props = {
  errors: Errors
  handleSubmit: Function
  loading: boolean
  initialFormData: FormData
}

const initialCustomer: Customer = {
    id: 0,
    first_name: "",
    last_name: "",
    street_address_1: "",
    street_address_2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    phone: "",
    email: "",
    notes: "",
    created: new Date(),
    modified: new Date(),
}
const initialCustomerErrors: Errors = {}

const initialOptions: Customer[] = []

const initialProducts: Product[] = []

const initialLeadSources: LeadSource[] = []

const initialReferrers: Referrer[] = []

export const OrderAddForm = ({ errors, handleSubmit, loading, initialFormData }: props) => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [customerErrors, setCustomerErrors] = useState(initialCustomerErrors)
  const [customerLoading, setCustomerLoading] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [defaultOptions, setDefaultOptions] = useState(initialOptions)
  const [products, setProducts] = useState(initialProducts)
  const [leadSources, setLeadSources] = useState(initialLeadSources)
  const [referrers, setReferrers] = useState(initialReferrers)
  const [optionsLoading, setOptionsLoading] = useState(true)

  const [isNewReferrer, setIsNewReferrer] = useState(false)

  const [product] = products.filter((v: Product) => v.id === parseInt(formData.product_id))

  useEffect(() => { setFormData(initialFormData) }, [initialFormData])
  useEffect(() => {
    setOptionsLoading(true)
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
        try {
          const response = await axiosPrivate.get('/products/get-list', {
            signal: controller.signal
          })
          isMounted && setProducts(response.data.products)
        } catch (err) {
          console.error(err)
        }

        try {
            const response = await axiosPrivate.get('/lead-sources/get-list', {
                signal: controller.signal
            })
            isMounted && setLeadSources(response.data.leadSources)
        } catch (err) {
            console.error(err)
        }

        try {
            const response = await axiosPrivate.get('/referrers/get-list', {
                signal: controller.signal
            })
            isMounted && setReferrers(response.data.referrers)
            setOptionsLoading(false)
        } catch (err) {
            console.error(err)
        }
    }

    const filterTimeout = setTimeout(getData, 500)

    return () => {
        isMounted = false
        isMounted && controller.abort()
        clearTimeout(filterTimeout)
    }
  }, [axiosPrivate])

  const handleCustomerChange = (c: Customer | null) => {
    setFormData({ ...formData, customer_id: c})
  }

  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const product = products.find((p: Product) => p.id === parseInt(e.target.value))
    if (product) {
      setFormData({
        ...formData,
        ...{
          product_id: product.id,
          order_details: { product_name: product.name },
        }
      })
    } else {
      setFormData({
        ...formData,
        ...{
          product_id: '',
          order_details: { product_name: '' },
        }
      })
    }
  }

  const handleDetailsChange = (field: string, value: string|number|boolean|(string|number)[]) => {
    const mutatedDetails = {
      order_details: {
        ...formData.order_details,
        [field]: value
      }
    }
    setFormData({
      ...formData,
      ...mutatedDetails
    })
  }

  const getCustomers = async (inputValue: string) => {
    const response = await axiosPrivate.get('/customers/index', {
        params: {
            query: inputValue
        }
    })

    return response.data.customers
  }

  const customerSubmit = async (formData: FormData) => {
    setCustomerLoading(true)
    try {
      const response = await axiosPrivate.post('/customers/add', formData)

      if (!response?.data?.customer?.errors) {
        toast({
          title: 'Success',
          description: 'Customer added successfully!',
          status: 'success',
          isClosable: true,
        })
        const customer = response.data.customer
        setDefaultOptions([customer])
        setFormData({ ...formData, customer_id: customer })
        onClose()
      } else {
        addServerErrors(response?.data?.customer?.errors, setCustomerErrors)
        toast({
          title: 'Error',
          description: 'Please review any errors and resubmit',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
    setCustomerLoading(false)
  }

  if (optionsLoading) {
    return (
      <Center>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Center>
    )
  }

  const leadSource = leadSources.find((l: LeadSource) => l.id === parseInt(formData.lead_source_id))
  const leadSourceOptions = leadSource?.options

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(formData) }}>
        <Stack spacing="5" px={{ base: '5', md: '10' }} py={{ base: '5', md: '10' }} w="100%">
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }} w="100%">
                <FormControl id="customer_id" isRequired isInvalid={'customer_id' in errors} w="100%">
                    <FormLabel>Customer</FormLabel>
                    <HStack w="100%">
                        <Stack w="100%">
                            <AsyncSelect
                                name="customer_id"
                                value={formData.customer_id}
                                onChange={handleCustomerChange}
                                placeholder="Search for an Existing Customer..."
                                cacheOptions
                                required={true}
                                defaultOptions={defaultOptions.length ? defaultOptions : true}
                                loadOptions={getCustomers}
                                getOptionLabel={(c: Customer) => c?.first_name ? `${c.first_name} ${c.last_name} (${c.email})` : 'Search for a Customer'}
                                getOptionValue={(c: Customer) => `${c.id}`}
                                styles={{
                                control: (css: any) => ({
                                    ...css,
                                    width: "100%"
                                })
                                }}
                            />
                            {errors.customer_id && <FormErrorMessage>{errors?.customer_id?.message}</FormErrorMessage>}
                        </Stack>
                        <Tooltip label="Add New Customer">
                            <IconButton
                                variant="solid"
                                colorScheme="green"
                                aria-label="Add Customer"
                                fontSize="20px"
                                icon={<AddIcon />}
                                onClick={onOpen}
                            />
                        </Tooltip>
                    </HStack>
                    <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="outside" size="xl" closeOnOverlayClick={false}>
                        <ModalOverlay />
                        <ModalContent maxW="56rem">
                            <ModalHeader>Add Customer</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <CustomerForm canCancel={true} edit={false} errors={customerErrors} handleSubmit={customerSubmit} loading={customerLoading} initialFormData={initialCustomer} />
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </FormControl>
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }} w="100%">
                <FormControl id="lead_source_id" isRequired isInvalid={'lead_source_id' in errors}>
                    <FormLabel>Lead Source</FormLabel>
                    <HStack>
                        <Select
                          name="lead_source_id"
                          defaultValue={formData.lead_source_id}
                          onChange={(e) => setFormData({
                            ...formData,
                            lead_source_id: e.target.value,
                            lead_source_other: '',
                          })}
                        >
                            <option value="">Select a Lead Source...</option>
                            {leadSources.map((l: LeadSource) => <option key={`lead-source-${l.id}`} value={l.id}>{l.name}</option>)}
                        </Select>
                        {errors.lead_source_id && <FormErrorMessage>{errors?.lead_source_id?.message}</FormErrorMessage>}
                    </HStack>
                </FormControl>
                {leadSource && leadSource.is_other && (
                    <FormControl id="lead_source_other" isRequired isInvalid={'lead_source_other' in errors}>
                        <FormLabel>Lead Source Other</FormLabel>
                        <HStack>
                            <Input
                              name="lead_source_other"
                              value={formData.lead_source_other}
                              onChange={(e) => setFormData({...formData, lead_source_other: e.target.value})}
                            />
                            {errors.lead_source_other && <FormErrorMessage>{errors?.lead_source_other?.message}</FormErrorMessage>}
                        </HStack>
                    </FormControl>
                )}
                {leadSource && !leadSource.is_other && leadSourceOptions && (
                    <FormControl id="lead_source_other" isRequired isInvalid={'lead_source_other' in errors}>
                        <FormLabel>Lead Source Option</FormLabel>
                        <HStack>
                            <Select
                              name="lead_source_other"
                              defaultValue={formData.lead_source_other}
                              onChange={(e) => setFormData({...formData, lead_source_other: e.target.value})}
                            >
                                <option value="">Select a Lead Source Option...</option>
                                {leadSourceOptions.map((o: string) => <option key={`lead-source-option-${o}`} value={o}>{o}</option>)}
                            </Select>
                            {errors.lead_source_other && <FormErrorMessage>{errors?.lead_source_other?.message}</FormErrorMessage>}
                        </HStack>
                    </FormControl>
                )}
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }} w="100%">
                <FormControl id="referrer_id" isInvalid={'referrer_id' in errors}>
                    <FormLabel>Referred By</FormLabel>
                        {isNewReferrer && (
                            <HStack w="100%">
                                <Stack w="100%">
                                    <Input
                                        name="new_referrer"
                                        value={formData.new_referrer}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            referrer_id: null,
                                            new_referrer: e.target.value,
                                        })}
                                        placeholder="Enter the name of the referrer..."
                                    />
                                    {errors.new_referrer && <FormErrorMessage>{errors?.new_referrer?.message}</FormErrorMessage>}
                                </Stack>
                                <Tooltip label="Back">
                                    <IconButton
                                        variant="solid"
                                        colorScheme="green"
                                        aria-label="Back"
                                        fontSize="20px"
                                        icon={<ArrowBackIcon />}
                                        onClick={() => {
                                            setFormData({
                                                ...formData,
                                                new_referrer: null,
                                            })
                                            setIsNewReferrer(false)
                                        }}
                                    />
                                </Tooltip>
                            </HStack>
                        )}
                        {!isNewReferrer && (
                             <HStack w="100%">
                                <Stack w="100%">
                                    <Select
                                        name="referrer_id"
                                        value={formData.referrer_id}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            referrer_id: e.target.value,
                                            new_referrer: null,
                                        })}
                                    >
                                        <option value="">Select a Referrer...</option>
                                        {referrers.map((r: Referrer) => <option key={`referrer-${r.id}`} value={r.id}>{r.name}</option>)}
                                    </Select>
                                    {errors.referrer_id && <FormErrorMessage>{errors?.referrer_id?.message}</FormErrorMessage>}
                                </Stack>
                                <Tooltip label="Add New Referrer">
                                    <IconButton
                                        variant="solid"
                                        colorScheme="green"
                                        aria-label="Add Referrer"
                                        fontSize="20px"
                                        icon={<AddIcon />}
                                        onClick={() => {
                                            setFormData({
                                                ...formData,
                                                referrer_id: null,
                                            })
                                            setIsNewReferrer(true)
                                        }}
                                    />
                                </Tooltip>
                            </HStack>
                        )}
                </FormControl>
            </Stack>
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }} w="100%">
                <FormControl id="product_id" isRequired isInvalid={'product_id' in errors}>
                    <FormLabel>Product</FormLabel>
                    <HStack>
                        <Select name="product_id" defaultValue={formData.product_id} onChange={handleProductChange}>
                            <option value="">Select a Product...</option>
                            {products.map((p: Product) => <option key={`product-${p.id}`} value={p.id}>{p.name}</option>)}
                        </Select>
                        {errors.product_id && <FormErrorMessage>{errors?.product_id?.message}</FormErrorMessage>}
                    </HStack>
                </FormControl>
            </Stack>
            {product && <OrderProductForm formData={formData} product={product} handleChange={handleDetailsChange} />}
            <Stack spacing="8" direction={{ base: 'column', md: 'row' }} w="100%">
                <FormControl id="notes" isInvalid={'notes' in errors}>
                    <FormLabel>Notes</FormLabel>
                    <HStack>
                        <Textarea
                          name="notes"
                          value={formData.notes}
                          onChange={(e) => setFormData({...formData, notes: e.target.value})}
                          placeholder="Enter any notes about the order..."
                        />
                        {errors.notes && <FormErrorMessage>{errors?.notes?.message}</FormErrorMessage>}
                    </HStack>
                </FormControl>
            </Stack>
            <Divider />
            <Flex justifyContent="space-between">
                <Button colorScheme="red" onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button colorScheme="blue" type="submit" isDisabled={loading}>
                {!loading
                    ? 'Submit'
                    : (
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='white'
                            size='lg'
                        />
                    )
                }
                </Button>
            </Flex>
        </Stack>
    </form>
  )
}
