import {
    Column,
    Table,
} from '@tanstack/react-table'
import {
    Input
  } from "@chakra-ui/react"

type props = {
    column: Column<any, any>
    table: Table<any>
}

export const PaginatedTableFilter = ({column, table}: props) => {
    if (column.columnDef.enableColumnFilter) {
      return null
    }

    const columnFilterValue = column.getFilterValue()

    return (
      <Input
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={e => column.setFilterValue(e.target.value)}
        placeholder={`Search...`}
        size="sm"
        maxW={220}
      />
    )
  }
