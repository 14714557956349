import { useEffect, useState } from "react"
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

type FormData = {
  [key: string]: any
}

type Errors = {
  [key: string]: any
}

type props = {
  canCancel?: boolean
  edit: boolean
  errors: Errors
  handleSubmit: Function
  loading: boolean
  initialFormData: FormData
}

export const CustomerForm = ({ canCancel = false, edit = false, errors, handleSubmit, loading, initialFormData }: props) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => { setFormData(initialFormData) }, [initialFormData])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); handleSubmit(formData) }}>
      <Stack spacing="5" px={{ base: '5', md: '10' }} py={{ base: '5', md: '10' }}>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="first_name" isRequired isInvalid={'first_name' in errors}>
          <FormLabel>First Name</FormLabel>
          <Input type="text" name="first_name" value={formData.first_name ?? ""} onChange={handleChange} />
          {errors.first_name && <FormErrorMessage>{errors?.first_name?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="last_name" isRequired isInvalid={'last_name' in errors}>
          <FormLabel>Last Name</FormLabel>
          <Input type="text" name="last_name" value={formData.last_name ?? ""} onChange={handleChange} />
          {errors.last_name && <FormErrorMessage>{errors?.last_name?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="street_address_1" isRequired={!edit} isInvalid={'street_address_1' in errors}>
          <FormLabel>Street Address</FormLabel>
          <Input type="text" name="street_address_1" value={formData.street_address_1 ?? ""} onChange={handleChange} />
          {errors.street_address_1 && <FormErrorMessage>{errors?.street_address_1?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="street_address_2" isInvalid={'street_address_2' in errors}>
          <FormLabel>Street Address 2</FormLabel>
          <Input type="text" name="street_address_2" value={formData.street_address_2 ?? ""} onChange={handleChange} />
          {errors.street_address_2 && <FormErrorMessage>{errors?.street_address_2?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="city" isRequired isInvalid={'city' in errors}>
        <FormLabel>City</FormLabel>
          <Input type="text" name="city" value={formData.city ?? ""} onChange={handleChange} />
          {errors.city && <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="state" isRequired isInvalid={'state' in errors}>
        <FormLabel>State</FormLabel>
          <Input type="text" name="state" value={formData.state ?? ""} onChange={handleChange} />
          {errors.state && <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="postal_code" isRequired isInvalid={'postal_code' in errors}>
        <FormLabel>Zipcode</FormLabel>
          <Input type="text" name="postal_code" value={formData.postal_code ?? ""} onChange={handleChange} />
          {errors.postal_code && <FormErrorMessage>{errors?.postal_code?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="country" isRequired isInvalid={'country' in errors}>
        <FormLabel>Country</FormLabel>
          <Input type="text" name="country" value={formData.country ?? ""} onChange={handleChange} />
          {errors.country && <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Stack spacing="8" direction={{ base: 'column', md: 'row' }}>
        <FormControl id="phone" isRequired isInvalid={'phone' in errors}>
          <FormLabel>Phone</FormLabel>
          <Input type="tel" name="phone" value={formData.phone ?? ""} onChange={handleChange} />
          {errors.phone && <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="email" isRequired isInvalid={'email' in errors}>
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" value={formData.email ?? ""} onChange={handleChange} />
          {errors.email && <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>}
        </FormControl>
        </Stack>
        <Divider />
        <Flex justifyContent={!canCancel ? "space-between" : "flex-end"}>
          {
            !canCancel && <Button colorScheme="red" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          }
          <Button colorScheme="blue" type="submit" isDisabled={loading}>
          {!loading
            ? 'Submit'
            : (
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='white'
              size='lg'
            />
            )
          }
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}
