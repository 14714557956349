import { useState } from "react"
import {
  Heading,
  useToast,
} from "@chakra-ui/react"
import { NavLayout } from "../components/NavLayout"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate } from "react-router-dom"
import { SaddlerForm } from "../components/Forms/SaddlerForm"
import { addServerErrors } from "../services/add-server-errors"

type FormData = {
  first_name: string
  last_name: string
  number: string
  active: string
}

type Errors = {
  [key: string]: { message: string }
}

const initialFormData: FormData = {
  first_name: "",
  last_name: "",
  number: "",
  active: "1",
}

const initialErrors: Errors = {}

export const AddSaddler = () => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const toast = useToast()
  const [errors, setErrors] = useState(initialErrors)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (formData: FormData) => {
    setLoading(true)
    try {
      const response = await axiosPrivate.post('/saddlers/add', formData)

      if (!response?.data?.user?.errors) {
        toast({
          title: 'Success',
          description: 'Saddler added successfully!',
          status: 'success',
          isClosable: true,
        })
        navigate(-1)
      } else {
        addServerErrors(response?.data?.user?.errors, setErrors)
        toast({
          title: 'Error',
          description: 'Please review any errors and resubmit',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
    setLoading(false)
  }

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Add Saddler</Heading>
      }
    >
      <SaddlerForm edit={false} errors={errors} handleSubmit={handleSubmit} initialFormData={initialFormData} loading={loading} />
    </NavLayout>
  )
}
