import { Outlet } from "react-router-dom"
import { useState, useEffect } from "react"
import useRefreshToken from "../hooks/useRefreshToken"
import useAuth from "../hooks/useAuth"
import { Box, Center, Spinner, VStack } from "@chakra-ui/react"

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    const { auth } = useAuth()

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh()
            } catch(err) {
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }

        !auth?.token ? verifyRefreshToken() : setIsLoading(false)
    }, [
        auth?.token,
        refresh
    ])

    return (
        <>
            {isLoading
                ? (
                    <Box bgColor="#F7FAFC" fontSize="xl" h="100vh">
                        <Center>
                            <VStack justifyContent="center" h="100vh">
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'
                                />
                            </VStack>
                        </Center>
                    </Box>
                )
                : <Outlet />
            }
        </>
    )
}

export default PersistLogin
