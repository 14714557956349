import { Navigate, Outlet, useLocation } from "react-router-dom"
import useAuth from "../hooks/useAuth"

type props = {
  allowedRoles: number[],
}

export const AuthRoute = ({ allowedRoles }: props) => {
    const { auth } = useAuth()
    const location = useLocation()

    return (
      (auth?.user?.role || auth?.user?.role === 0)
        ? allowedRoles?.includes(auth?.user?.role)
          ? <Outlet />
          : <Navigate to="/orders" state={{ from: location }} replace />
        : <Navigate to="/" state={{ from: location }} replace />
    )
}
