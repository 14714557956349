import { useEffect, useState } from "react"
import {
  Heading,
  useToast,
} from "@chakra-ui/react"
import { NavLayout } from "../components/NavLayout";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { addServerErrors } from "../services/add-server-errors";
import { OrderEditForm } from "../components/Forms/OrderEditForm";
import useAuth from "../hooks/useAuth";

type Errors = {
  [key: string]: { message: string }
}

const initialErrors: Errors = {};

const extractFormData = (data: any) => {
  return {
    id: data.id ?? null,
    lead_source_id: data.lead_source_id ?? null,
    lead_source_other: data.lead_source_other ?? null,
    saddler_id: data.saddler_id ?? null,
    referrer_id: data.referrer_id ?? null,
    order_number: data.order_number ?? null,
    order_details: data.order_details ?? {},
    notes: data.notes ?? null,
    special_instructions: data.special_instructions ?? null,
    paperwork_received: data.paperwork_received ?? null,
    estimated_ship_date: data.estimated_ship_date ?? null,
    actual_ship_date: data.actual_ship_date ?? null,
    job_ticket: data.job_ticket ?? null,
    tracking_url: data.tracking_url ?? null,
    serial_number: data.serial_number ?? null,
    archived: data.archived ?? null,
    created: data.created ?? null,
    customer: data.customer ?? null,
    user: data.user ?? null,
  }
};

export const EditOrder = () => {
  useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const toast = useToast()
  const { orderId } = useParams()
  const [data, setData] = useState({})
  const [index, setIndex] = useState<number[]>([])
  const [errors, setErrors] = useState(initialErrors)
  const [loading, setLoading] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const endpoint = `/orders/edit/${orderId}`

  useEffect(() => {
    setLoading(true)
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
        try {
          const response = await axiosPrivate.get(endpoint, {
            signal: controller.signal
          })
          if (isMounted) {
            setData(extractFormData(response.data.order));
            setIndex(response.data.order.index ?? [])
          }
          setLoading(false)
        } catch (err: any) {
          console.error(err)
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            isClosable: true,
          })
          navigate('/orders')
        }
    }

    const timeout = setTimeout(getData, 500)

    return () => {
        isMounted = false
        isMounted && controller.abort()
        clearTimeout(timeout)
    }
  }, [axiosPrivate, endpoint, navigate, toast])

  const handleSubmit = async (formData: FormData) => {
    setLoading(true)
    try {
      const response = await axiosPrivate.post(endpoint, formData)

      if (!response?.data?.order?.errors) {
        toast({
          title: 'Success',
          description: 'Order saved successfully!',
          status: 'success',
          isClosable: true,
        })
        setFormChanged(false)
        setData(extractFormData(response.data.order))
      } else {
        addServerErrors(response?.data?.order?.errors, setErrors)
        toast({
          title: 'Error',
          description: 'Please review any errors and resubmit',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
    setLoading(false)
  }

  const prevOrderId = orderId ? index[index.indexOf(parseInt(orderId)) - 1] : undefined
  const nextOrderId = orderId ? index[index.indexOf(parseInt(orderId)) + 1] : undefined

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Edit Order</Heading>
      }
    >
      <OrderEditForm
        errors={errors}
        handleSubmit={handleSubmit}
        initialFormData={data}
        loading={loading}
        prevOrderId={prevOrderId}
        nextOrderId={nextOrderId}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
      />
    </NavLayout>
  )
}
