import { useEffect, useState } from "react"
import {
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react"
import { NavLayout } from "../components/NavLayout"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate, useParams } from "react-router-dom"

type ProductCodes = {
  [key: string]: {
    name: string
    slug: string
    product: string
    seat_size: string
    tree_size: string
    code: string
  }
}

const treeSizeOrder = [
  'Narrow (N)',
  'Medium (M)',
  'Wide (W)',
  'Extra Wide (XW)',
  'Extra Extra Wide (XXW)',
  'Medium Narrow (MN)',
  'Medium Wide (MW)',
  'Wide Extra Wide (WXW)',
]

// order codes first by seat size, then by tree size using the treeSizeOrder array
const orderCodes = function (codes: ProductCodes) {
  const ordered = Object.entries(codes).sort((a, b) => {
    if (a[1].seat_size < b[1].seat_size) {
      return -1
    }
    if (a[1].seat_size > b[1].seat_size) {
      return 1
    }
    if (a[1].seat_size === b[1].seat_size) {
      return treeSizeOrder.indexOf(a[1].tree_size) - treeSizeOrder.indexOf(b[1].tree_size)
    }
    return 0
  })

  return Object.fromEntries(ordered)
}

export const EditProductCodes = () => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const toast = useToast()
  const { productId } = useParams()
  const [codes, setCodes] = useState<ProductCodes | null>(null)
  const [loading, setLoading] = useState(false)
  const endpoint = `/products/edit/${productId}`

  useEffect(() => {
    setLoading(true)
    let isMounted = true
    const controller = new AbortController()

    const getData = async () => {
      try {
        const response = await axiosPrivate.get(endpoint, {
          signal: controller.signal
        })
        console.log(response.data.product.codes)
        isMounted && setCodes(response.data.product.codes as ProductCodes)
        setLoading(false)
      } catch (err: any) {
        console.error(err)
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          isClosable: true,
        })
        navigate(-1)
      }
    }

    const timeout = setTimeout(getData, 500)

    return () => {
      isMounted = false
      isMounted && controller.abort()
      clearTimeout(timeout)
    }
  }, [axiosPrivate, endpoint, navigate, toast])

  const handleSubmit = async () => {
    try {
      const response = await axiosPrivate.post(endpoint, {codes: codes})

      if (!response?.data?.product?.errors) {
        toast({
          title: 'Success',
          description: 'Product codes successfully updated!',
          status: 'success',
          isClosable: true,
        })
        navigate(-1)
      } else {
        toast({
          title: 'Error',
          description: 'Please review any errors and resubmit',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (e: any) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Edit Product Codes</Heading>
      }
    >
      {!loading
        ? (
          <Stack spacing="5" px={{ base: '5' }} py={{ base: '5' }}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Product</Th>
                  <Th>Seat Size</Th>
                  <Th>Tree Sizes</Th>
                  <Th>Code</Th>
                </Tr>
              </Thead>
              <Tbody>
                {codes && Object.entries(orderCodes(codes)).map(([key, value]) => (
                  <Tr key={key}>
                    <Td>{value.product}</Td>
                    <Td>{value.seat_size}</Td>
                    <Td>{value.tree_size}</Td>
                    <Td>
                      <Input
                        variant="filled"
                        value={value.code}
                        onChange={(e) => {
                          const newCodes = { ...codes }
                          newCodes[key].code = e.target.value
                          setCodes(newCodes)
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Flex justifyContent="space-between">
              <Button colorScheme="red" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={() => handleSubmit()} isDisabled={loading}>
              {!loading
                ? 'Submit'
                : (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='white'
                  size='lg'
                />
                )
              }
              </Button>
            </Flex>
          </Stack>
        ) : (
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        )
      }
    </NavLayout>
  )
}
