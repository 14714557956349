import { useMemo } from "react"
import {
  Button,
  Heading,
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { ColumnDef } from "@tanstack/react-table"
import { AddIcon } from "@chakra-ui/icons"
import { PaginatedTable } from "../components/PaginatedTable"
import { Product } from "../models/Product"
import { NavLayout } from "../components/NavLayout"

export const Products = () => {
  const columns = useMemo<ColumnDef<Product>[]>(
    () => [
      {
        accessorKey: 'id',
        id: 'id'
      },
      {
        accessorKey: 'name',
        header: () => 'Name',
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.active ? 'Yes' : 'No',
        accessorKey: 'active',
        header: () => 'Active',
        footer: props => props.column.id,
      },
    ],
    []
  )

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Products</Heading>
      }
      right={
        <Button
          as={ReactRouterLink}
          to="/products/add"
          boxShadow="dark-lg"
          variant="solid"
          colorScheme="green"
          aria-label="Add Product"
          fontSize="20px"
          leftIcon={<AddIcon />}
        >
          Add Product
        </Button>
      }
    >
      <PaginatedTable<Product> dataKey="products" singular="Product" endpoint="/products/index" columns={columns} />
    </NavLayout>
  )
}
